<template>
  <div class="sopimus-nayta">
    <ViewTopBarBase
      :title="routeTitle"
    >
      <v-menu
        bottom
        left
        nudge-bottom="40"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="success"
            depressed
            v-on="on"
          >
            {{ $t('common:actions') }}
            <v-icon right>
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <v-list>
            <v-list-item
              @click="siirrySopimuksenMuokkaukseen"
            >
              <v-list-item-avatar>
                <v-icon>edit</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:sopimusNayta.editControlInformation') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="avaaDialogAsiakirja"
            >
              <v-list-item-avatar>
                <v-icon>attach_file</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:sopimusNayta.addDocuments') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="avaaDialogHyvitaSopimusta"
            >
              <v-list-item-avatar>
                <v-icon>euro</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:sopimusNayta.makeRefund') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="sopimusNayta.sopimus.tila.value === 'A'"
              @click="avaaDialogPaataSopimus"
            >
              <v-list-item-avatar>
                <v-icon>block</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:sopimusNayta.stopBilling') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-else-if="sopimusNayta.sopimus.tila.value === 'P'"
              @click="avaaDialogAktivoiSopimus"
            >
              <v-list-item-avatar>
                <v-icon>start</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:sopimusNayta.activateBilling') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="avaaDialogPaamiesSiirra"
            >
              <v-list-item-avatar>
                <v-icon>redo</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:sopimusNayta.transferToAnotherCreditor') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </ViewTopBarBase>

    <Spinner v-if="tietueLataamassa" />
    <CardPahoittelu v-else-if="tietueLatausEpaonnistunut" />
    <template v-else>
      <v-container
        class="pa-2"
        fluid
      >
        <!-- Tiedot -->
        <div class="pa-4">
          <TietueenPerustiedotCard
            :card-class="(sopimusNayta.sopimus.onko_myohassa ? 'yellow lighten-5' : null)"
            :paamies="sopimusNayta.sopimus.paamies"
            :vastapuoli="sopimusNayta.sopimus.asiakas"
          >
            <template v-slot:col2>
              <DefList :bordered="(true)">
                <DefListItem
                  label-class="defList__label--emphasized"
                  value-class="font-weight-bold"
                >
                  <template v-slot:label>
                    {{ $t('common:contractNumber') }}
                  </template>
                  <template
                    v-if="sopimusNayta.sopimus.nro"
                    v-slot:value
                  >
                    {{ sopimusNayta.sopimus.nro }}
                  </template>
                </DefListItem>
                <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.status')">
                  <template v-slot:label>
                    {{ $t('common:status') }}
                  </template>
                  <template
                    v-if="sopimusNayta.sopimus.tila && sopimusNayta.sopimus.ikoni_ja_vari"
                    v-slot:value
                  >
                    <StatusText
                      :color="sopimusNayta.sopimus.ikoni_ja_vari.vari"
                      :text="sopimusNayta.sopimus.tila.selite"
                    />
                  </template>
                </DefListTooltipItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $sovellusIdOtsake }}
                  </template>
                  <template v-slot:value>
                    {{ sopimusNayta.sopimus.id }}
                  </template>
                </DefListItem>
                <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.reference')">
                  <template v-slot:label>
                    {{ $t('common:invoiceReference') }}
                  </template>
                  <template v-slot:value>
                    {{ sopimusNayta.sopimus.viite }}
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem
                  v-if="sopimusNayta.sopimus.viitteemme"
                  :tooltip="$t('common:infotekstit.sopimusInfo.ourReference')"
                  value-class="text-wrap"
                >
                  <template v-slot:label>
                    {{ $t('common:ourReference') }}
                  </template>
                  <template v-slot:value>
                    <PerustietoTooltip :teksti="sopimusNayta.sopimus.viitteemme" />
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem
                  v-if="sopimusNayta.sopimus.viitteenne"
                  :tooltip="$t('common:infotekstit.sopimusInfo.yourReference')"
                  value-class="text-wrap"
                >
                  <template v-slot:label>
                    {{ $t('common:yourReference') }}
                  </template>
                  <template v-slot:value>
                    <PerustietoTooltip :teksti="sopimusNayta.sopimus.viitteenne" />
                  </template>
                </DefListTooltipItem>
              </DefList>
            </template>
            <template v-slot:col3>
              <DefList :bordered="(true)">
                <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.startingDate')">
                  <template v-slot:label>
                    {{ $t('common:startingDate') }}
                  </template>
                  <template
                    v-if="sopimusNayta.sopimus.alkamispaiva"
                    v-slot:value
                  >
                    {{ $date(sopimusNayta.sopimus.alkamispaiva) }}
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.endingDate')">
                  <template v-slot:label>
                    {{ $t('common:endingDate') }}
                  </template>
                  <template
                    v-slot:value
                  >
                    <span v-if="sopimusNayta.sopimus.loppumispaiva">{{ $date(sopimusNayta.sopimus.loppumispaiva) }}</span>
                    <span v-else>{{ $t('common:validForNow') }}</span>
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.invoicePeriod')">
                  <template v-slot:label>
                    {{ $t('common:invoicingPeriod') }}
                  </template>
                  <template
                    v-slot:value
                  >
                    {{ muotoiltuSopimuksenLaskutusjakso }}
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem
                  v-if="sopimusNayta.sopimus.laskutuspaiva"
                  :tooltip="$t('common:infotekstit.sopimusInfo.invoiceDate')"
                >
                  <template v-slot:label>
                    {{ $t('common:invoicingDate') }}
                  </template>
                  <template
                    v-slot:value
                  >
                    {{ $t('common:sopimusNayta.monthDay', { invoicingDay: sopimusNayta.sopimus.laskutuspaiva }) }}
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem
                  v-else-if="sopimusNayta.sopimus.erapaiva"
                  :tooltip="$t('common:infotekstit.sopimusInfo.dueDate')"
                >
                  <template v-slot:label>
                    {{ $t('common:dueDate') }}
                  </template>
                  <template
                    v-slot:value
                  >
                    {{ $t('common:sopimusNayta.monthDay', { invoicingDay: sopimusNayta.sopimus.erapaiva }) }}
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.paymentTerm')">
                  <template v-slot:label>
                    {{ $t('common:termsOfPayment') }}
                  </template>
                  <template
                    v-if="sopimusNayta.sopimus.maksuehto"
                    v-slot:value
                  >
                    {{ muotoileMaksuaika(sopimusNayta.sopimus.maksuehto) }}
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem
                  v-if="sopimusNayta.sopimus.laskutuspaiva && sopimusNayta.sopimus.seuraava_laskutuspaiva"
                  :tooltip="$t('common:infotekstit.sopimusInfo.nextInvoiceDate')"
                >
                  <template v-slot:label>
                    {{ $t('common:nextInvoiceDate') }}
                  </template>
                  <template v-slot:value>
                    {{ $date(sopimusNayta.sopimus.seuraava_laskutuspaiva) }}
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem
                  v-else-if="sopimusNayta.sopimus.erapaiva && sopimusNayta.sopimus.seuraava_erapaiva"
                  :tooltip="$t('common:infotekstit.sopimusInfo.nextInvoiceDate')"
                >
                  <template v-slot:label>
                    {{ $t('common:nextDueDate') }}
                  </template>
                  <template v-slot:value>
                    {{ $date(sopimusNayta.sopimus.seuraava_erapaiva) }}
                  </template>
                </DefListTooltipItem>
              </DefList>
            </template>
            <template v-slot:col4>
              <DefList :bordered="(true)">
                <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.letterhead')">
                  <template v-slot:label>
                    {{ $t('common:sopimusNayta.invoiceBase') }}
                  </template>
                  <template v-slot:value>
                    <span v-if="sopimusNayta.sopimus.kirjepohja">{{ sopimusNayta.sopimus.kirjepohja.id }}: {{ sopimusNayta.sopimus.kirjepohja.nimi }}</span>
                  </template>
                </DefListTooltipItem>
                <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.invoiceSending')">
                  <template v-slot:label>
                    {{ $t('common:sopimusNayta.invoiceSend') }}
                  </template>
                  <template v-slot:value>
                    {{ muotoiltuLaskunLahetysTeksti }}
                  </template>
                </DefListTooltipItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:lateInterest') }}
                  </template>
                  <template v-slot:value>
                    <span v-if="sopimusNayta.sopimus.viivastyskorko">{{ sopimusNayta.sopimus.viivastyskorko }}</span>
                    <span v-else>{{ $t('common:sopimusNayta.accordingToKsl') }}</span>
                  </template>
                </DefListItem>
                <DefListItem v-if="sopimusNayta.sopimus.laji">
                  <template v-slot:label>
                    {{ $t('common:typeOfContract') }}
                  </template>
                  <template v-slot:value>
                    <span>{{ sopimusNayta.sopimus.laji }}</span>
                  </template>
                </DefListItem>
                <DefListTooltipItem
                  v-if="sopimusNayta.sopimus.laskunteksti"
                  :tooltip="$t('common:infotekstit.sopimusInfo.invoiceText')"
                  label-class="text-wrap"
                  value-class="text-wrap"
                >
                  <template v-slot:label>
                    {{ $t('common:invoiceText') }}
                  </template>
                  <template v-slot:value>
                    <PerustietoTooltip :teksti="sopimusNayta.sopimus.laskunteksti" />
                  </template>
                </DefListTooltipItem>
                <template
                  v-if="sopimusNayta.sopimus.kassaalennus_prosentti && sopimusNayta.sopimus.kassaalennus_prosentti !== '0.00'"
                >
                  <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.cashDiscountPercent')">
                    <template v-slot:label>
                      {{ $t('common:sopimusNayta.cashDiscountPercent') }}
                    </template>
                    <template v-slot:value>
                      {{ sopimusNayta.sopimus.kassaalennus_prosentti }}
                    </template>
                  </DefListTooltipItem>
                  <DefListTooltipItem :tooltip="$t('common:infotekstit.sopimusInfo.cashDiscountPaymentPeriod')">
                    <template v-slot:label>
                      {{ $t('common:sopimusNayta.cashSalePaymentPeriod') }}
                    </template>
                    <template v-slot:value>
                      {{ muotoileMaksuaika(sopimusNayta.sopimus.kassaalennus_maksuaika) }}
                    </template>
                  </DefListTooltipItem>
                </template>
              </DefList>
            </template>
          </TietueenPerustiedotCard>
        </div>

        <div class="pa-4">
          <CardVaroitus
            v-if="!tasmaakoSeuraavaLaskutuspaiva"
            :teksti="sopimusNayta.sopimus.seuraavaLaskutuspaivaVaroitus()"
          />
        </div>


        <div class="pa-4">
          <v-row>
            <!-- Vasen sarake -->
            <v-col
              cols="12"
              lg="7"
            >
              <!-- Laskurivit -->
              <div class="pb-4">
                <v-card class="pa-6">
                  <CardTitle text="Laskurivit" />

                  <v-data-table
                    :items="laskurivitItems"
                    :headers="tableHeadersSopimusLaskusisalto"
                    :hide-default-footer="laskurivitItems.length <= 5"
                    no-data-text="Ei laskurivejä"
                    :dense="user.tiivisNakyma"
                    :loading="tableLaskusisaltoLoading"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        flat
                        short
                        :dense="user.tiivisNakyma"
                      >
                        <TooltipInfo
                          icon-color="primary"
                          :text="$t('common:infotekstit.sopimusInfo.invoiceLines')"
                        />
                        <v-spacer></v-spacer>
                        <v-btn
                          small
                          color="primary"
                          @click="lisaaLaskusisalto"
                        >
                          <v-icon left>
                            add
                          </v-icon>
                          {{ $t('common:sopimusNayta.addInvoiceRow') }}
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap">
                          {{ item.koodi }}
                        </td>
                        <td class="max-width">
                          {{ item.selite }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.alvp }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.ahinta | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $muotoileDesimaaliluku(item.maara) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.veroton | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.vero | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.verollinen | formatSumToFixed2 }}
                        </td>
                        <td>
                          <v-tooltip top>
                            <template
                              v-slot:activator="{ on }"
                            >
                              <v-btn
                                text
                                icon
                                small
                                v-on="on"
                                @click="muokkaaLaskusisaltoa(item)"
                              >
                                <v-icon
                                  small
                                >
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:default>
                              <span>{{ $t('common:sopimusNayta.editInvoiceRow') }}</span>
                            </template>
                          </v-tooltip>
                          <v-tooltip top>
                            <template
                              v-slot:activator="{ on }"
                            >
                              <v-btn
                                text
                                icon
                                small
                                v-on="on"
                                @click="avaaLaskusisaltoPoistaDialog(item.id)"
                              >
                                <v-icon
                                  small
                                >
                                  delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:default>
                              <span>{{ $t('common:sopimusNayta.deleteInvoiceRow') }}</span>
                            </template>
                          </v-tooltip>
                        </td>
                      </TableRow>
                    </template>
                    <template
                      v-if="laskurivitItems.length"
                      v-slot:body.append
                    >
                      <TableRow>
                        <td
                          colspan="5"
                          class="text-no-wrap text-right"
                        >
                          <strong>Yhteensä</strong>
                        </td>
                        <td class="text-right">
                          <strong>{{ sopimusNayta.sopimus.sopimuslaskusisalto_yhteenveto.veroton | formatSumToFixed2 }}</strong>
                        </td>
                        <td class="text-no-wrap text-right">
                          <strong>{{ sopimusNayta.sopimus.sopimuslaskusisalto_yhteenveto.vero | formatSumToFixed2 }}</strong>
                        </td>
                        <td class="text-no-wrap text-right">
                          <strong>{{ sopimusNayta.sopimus.sopimuslaskusisalto_yhteenveto.verollinen | formatSumToFixed2 }}</strong>
                        </td>
                        <td></td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </v-col>
            <!-- Oikea sarake -->
            <v-col
              cols="12"
              lg="5"
            >
              <div class="pb-4">
                <v-card class="pa-6">
                  <CardTitle text="Yhteenveto" />
                  <SopimusYhteenveto :sopimus="sopimusNayta.sopimus" />
                </v-card>
              </div>
            </v-col>

            <!-- Leveä sarake -->
            <v-col>
              <!--  Laskutusjaksot / Lainasopimuksen laskutusaiheet -->
              <div class="pb-4">
                <v-card class="pa-6">
                  <CardTitle :text="sopimusNayta.sopimus.onko_lainasopimus ? 'Laskutusaiheet' : 'Laskutusjaksot'" />
                  <v-data-table
                    ref="tableLaskutusjakso"
                    :items="laskutusjaksoItems"
                    :headers="tableHeadersLaskutusjakso"
                    :hide-default-footer="laskutusjaksoItems.length <= 5"
                    :items-per-page="5"
                    :loading="tableLaskutusjaksoLoading"
                    :footer-props="{
                      itemsPerPageText: 'Laskutusjaksoja per sivu',
                      itemsPerPageOptions: [3,5,10,-1],
                    }"
                    no-data-text="Ei laskutusjaksoja"
                    dense
                    :page="tableLaskutusjaksoAloitussivu"
                    :disable-sort="(true)"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        flat
                        short
                        :dense="user.tiivisNakyma"
                      >
                        <span></span>
                        <v-spacer></v-spacer>
                        <v-btn
                          small
                          color="primary"
                          :disabled="!sopimusNayta.sopimus.laskutettavissa"
                          @click="avaaLaskutusjaksoLisaaDialog"
                        >
                          <v-icon left>
                            add
                          </v-icon>
                          Lisää laskutusjaksoja
                        </v-btn>
                        <v-btn
                          small
                          color="primary"
                          class="ml-2"
                          @click="avaaLaskutusaihePoistaLaskuttamattomatDialog"
                        >
                          <v-icon
                            left
                            small
                          >
                            delete
                          </v-icon>
                          Poista laskuttamattomat laskutusaiheet
                        </v-btn>
                        <v-btn
                          small
                          color="primary"
                          class="ml-2"
                          :disabled="!sopimusNayta.sopimus.laskutettavissa"
                          @click="avaaLaskutusaiheetPaivitaDialog"
                        >
                          <v-icon
                            left
                            small
                          >
                            update
                          </v-icon>
                          Päivitä laskuttamattomat laskutusaiheet
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template v-slot:item="{ item }">
                      <TableRow
                        v-if="item.laskutusjakso"
                        :sub-row="(true)"
                      >
                        <td class="text-no-wrap">
                          <strong>{{ item.laskutusjakso }}</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-no-wrap">
                          <strong>{{ item.selite }}</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <v-tooltip top>
                            <template
                              v-slot:activator="{ on }"
                            >
                              <v-btn
                                text
                                icon
                                small
                                v-on="on"
                                @click="muokkaaLaskutusjaksoa(item)"
                              >
                                <v-icon
                                  small
                                >
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:default>
                              <span>Muokkaa laskutusjaksoa</span>
                            </template>
                          </v-tooltip>
                          <v-tooltip top>
                            <template
                              v-slot:activator="{ on }"
                            >
                              <v-btn
                                text
                                icon
                                small
                                v-on="on"
                                @click="avaaLaskutaLaskutusjaksoDialog(item.id)"
                              >
                                <v-icon
                                  small
                                >
                                  anitta-icon-invoice
                                </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:default>
                              <span>Laskuta laskutusjakso</span>
                            </template>
                          </v-tooltip>
                          <v-tooltip top>
                            <template
                              v-slot:activator="{ on }"
                            >
                              <v-btn
                                text
                                icon
                                small
                                v-on="on"
                                @click="lisaaLaskutusaihe(item)"
                              >
                                <v-icon
                                  small
                                >
                                  add_circle
                                </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:default>
                              <span>Lisää laskutusjaksolle laskutusaihe</span>
                            </template>
                          </v-tooltip>
                          <v-tooltip top>
                            <template
                              v-slot:activator="{ on }"
                            >
                              <v-btn
                                text
                                icon
                                small
                                v-on="on"
                                @click="avaaDialogLaskutusjaksonLiite(item.id)"
                              >
                                <v-icon
                                  small
                                >
                                  attach_file
                                </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:default>
                              <span>Lisää liite laskutusjaksolle</span>
                            </template>
                          </v-tooltip>
                          <v-tooltip
                            v-if="item.tiedosto_set.length"
                            top
                          >
                            <template
                              v-slot:activator="{ on }"
                            >
                              <v-btn
                                text
                                icon
                                small
                                v-on="on"
                                @click="avaaMenuContextLaskutusjaksoLiite($event, item)"
                              >
                                <v-icon
                                  small
                                >
                                  anitta-icon-file-download
                                </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:default>
                              <span>Lataa laskutusjakson liitteitä</span>
                            </template>
                          </v-tooltip>
                        </td>
                      </TableRow>
                      <TableRow
                        v-for="laskutusaihe in item.subRows"
                        :key="laskutusaihe.id"
                      >
                        <td v-if="!sopimusNayta.sopimus.onko_lainasopimus"></td>
                        <td>
                          <v-tooltip top>
                            <template
                              v-if="laskutusaihe.lasku"
                              v-slot:activator="{ on }"
                            >
                              <StatusIcon
                                v-if="laskutusaihe.lasku && laskutusaihe.lasku.ikoni_ja_vari"
                                :icon="laskutusaihe.lasku.ikoni_ja_vari.ikoni"
                                size="small"
                                :hover-icon="('open-in-new')"
                                :bg-color="laskutusaihe.lasku.ikoni_ja_vari.vari"
                                :event-listener="on"
                                @click="$router.push({ name: 'laskutus-nayta', params: { id: String(laskutusaihe.lasku.id) } })"
                              />
                            </template>
                            <template v-slot:default>
                              <span>Avaa laskun tiedot</span>
                            </template>
                          </v-tooltip>
                        </td>
                        <td class="text-no-wrap">
                          <span
                            v-if="laskutusaihe.lasku"
                            @contextmenu.prevent="avaaMenuContext($event, { id: laskutusaihe.lasku.id }, 'laskutus-nayta')"
                          >
                            <router-link :to="{ name: 'laskutus-nayta', params: { id: laskutusaihe.lasku.id } }">
                              {{ laskutusaihe.lasku.nro }}
                            </router-link>
                          </span>
                        </td>
                        <td class="text-no-wrap">
                          {{ laskutusaihe.laskutuspaiva_display }}
                        </td>
                        <td class="text-no-wrap">
                          {{ laskutusaihe.lasku ? laskutusaihe.lasku.tila.selite : 'Laskuttamatta' }}
                        </td>
                        <td class="text-no-wrap">
                          {{ laskutusaihe.erapaiva }}
                        </td>
                        <td class="max-width">
                          {{ laskutusaihe.selite }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ laskutusaihe.alvtunnus ? laskutusaihe.alvtunnus.alvp : laskutusaihe.alvp }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ laskutusaihe.ahinta | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $muotoileDesimaaliluku(laskutusaihe.maara) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ laskutusaihe.veroton | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ laskutusaihe.verollinen | formatSumToFixed2 }}
                        </td>
                        <td>
                          <v-tooltip top>
                            <template
                              v-slot:activator="{ on }"
                            >
                              <v-btn
                                text
                                icon
                                small
                                :disabled="Boolean(laskutusaihe.lasku)"
                                v-on="on"
                                @click="muokkaaLaskutusaihetta(laskutusaihe)"
                              >
                                <v-icon
                                  small
                                  :disabled="Boolean(laskutusaihe.lasku)"
                                >
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:default>
                              <span>Muokkaa laskutusaihetta</span>
                            </template>
                          </v-tooltip>
                          <v-tooltip top>
                            <template
                              v-slot:activator="{ on }"
                            >
                              <v-btn
                                text
                                icon
                                small
                                :disabled="Boolean(laskutusaihe.lasku)"
                                v-on="on"
                                @click="avaaLaskutusaihePoistaDialog(laskutusaihe.id)"
                              >
                                <v-icon
                                  small
                                  :disabled="Boolean(laskutusaihe.lasku)"
                                >
                                  delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:default>
                              <span>Poista laskutusaihe</span>
                            </template>
                          </v-tooltip>
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Asiakirjat -->
              <div
                v-if="sopimusAsiakirjaItems.length"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle text="Asiakirjat" />

                  <DataTableSopimusAsiakirja
                    :items="sopimusAsiakirjaItems"
                    :sopimus="sopimusNayta.sopimus"
                  />
                </v-card>
              </div>

              <!-- Kassa-alennukset -->
              <div
                v-if="kassaAlennuksetItems.length"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle text="Kassa-alennukset" />

                  <v-data-table
                    :items="kassaAlennuksetItems"
                    :headers="tableHeadersKassaAlennukset"
                    :hide-default-footer="(true)"
                    :items-per-page="999"
                    no-data-text="Ei kassa-alennuksia"
                    :dense="user.tiivisNakyma"
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap">
                          {{ $date(item.voimassa) }}
                        </td>
                        <td class="text-no-wrap">
                          {{ item.alennusp }}
                        </td>
                        <td class="text-no-wrap max-width">
                          {{ item.alennus | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Lisätiedot -->
              <!-- Piilotetaan toistaiseksi lisätiedot -->
              <!-- <div
                v-if="lisatiedotItems.length"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle text="Lisätiedot" />
                  <template>
                    <v-data-table
                      :items="lisatiedotItems"
                      :headers="tableHeadersSopimusLisatiedot"
                      :hide-default-footer="(true)"
                      :items-per-page="999"
                      no-data-text="Ei lisätietoja"
                      dense
                    >
                      <template v-slot:item="{ item }">
                        <TableRow>
                          <td class="text-no-wrap">
                            <strong>{{ item.key }}</strong>
                          </td>
                          <td class="max-width">
                            {{ item.value }}
                          </td>
                          <td class="text-no-wrap">
                            {{ $date(item.luotu) }}
                          </td>
                        </TableRow>
                      </template>
                    </v-data-table>
                  </template>
                </v-card>
              </div> -->
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>

    <!-- Dialog: Lisää asiakirjoja -->
    <CardDialog
      title="Lisää asiakirjoja"
      :dialog-auki="asiakirjaDialogAuki"
      @close="(asiakirjaDialogAuki = false)"
    >
      <FormLiitteet
        v-model="sopimusNayta.asiakirjaFormData"
        class="pa-4"
        :loading="asiakirjaFormLoading"
        :on-auki="asiakirjaDialogAuki"
        @cancel="asiakirjaDialogAuki = false"
        @submit="asiakirjaSubmit"
        @close="(asiakirjaDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Hyvitä sopimusta -->
    <CardDialog
      title="Hyvitä sopimusta"
      :dialog-auki="hyvitaSopimustaDialogAuki"
      @close="(hyvitaSopimustaDialogAuki = false)"
    >
      <FormSopimusHyvita
        v-model="sopimusNayta.hyvitaSopimustaFormData"
        class="pa-4"
        :loading="hyvitaSopimustaFormLoading"
        :on-auki="hyvitaSopimustaDialogAuki"
        @cancel="hyvitaSopimustaDialogAuki = false"
        @submit="hyvitaSopimustaSubmit"
        @close="(hyvitaSopimustaDialogAuki = false)"
      />
    </CardDialog>


    <!-- Dialog: Välilehti -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />

    <!-- Laskutusjakso-taulukon dialogit -->

    <!-- Dialog: Lisää laskutusjaksolle liite -->
    <CardDialog
      title="Lisää laskutusjaksolle liite"
      :dialog-auki="laskutusjaksonLiiteDialogAuki"
      @close="(laskutusjaksonLiiteDialogAuki = false)"
    >
      <FormLiite
        v-model="sopimusNayta.laskutusjaksonLiiteFormData"
        class="pa-4"
        :loading="laskutusjaksonLiiteFormLoading"
        :on-auki="laskutusjaksonLiiteDialogAuki"
        @cancel="laskutusjaksonLiiteDialogAuki = false"
        @submit="laskutusjaksonLiiteSubmit"
        @close="(laskutusjaksonLiiteDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Laskutusisalto -->
    <CardDialog
      :title="sopimusNayta.laskusisaltoKirjoitusmoodi === kirjoitusmoodit.LUOMINEN ? 'Uusi laskurivi' : 'Muokkaa laskuriviä'"
      :dialog-auki="laskusisaltoDialogAuki"
      width="88%"
      @close="(laskusisaltoDialogAuki = false)"
    >
      <FormSopimusLaskusisalto
        ref="laskusisaltoDialog"
        v-model="sopimusNayta.laskusisaltoFormData"
        class="pa-4"
        :loading="laskusisaltoFormLoading"
        :kirjoitusmoodi="sopimusNayta.laskusisaltoKirjoitusmoodi"
        :on-auki="laskusisaltoDialogAuki"
        @cancel="laskusisaltoDialogAuki = false"
        @submit="laskusisaltoSubmit"
        @close="(laskusisaltoDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Laskutusaiheen muokkaus -->
    <CardDialog
      :title="sopimusNayta.laskutusaiheKirjoitusmoodi === kirjoitusmoodit.LUOMINEN ? 'Luo laskutusaihe' : 'Muokkaa laskutusaihetta'"
      :dialog-auki="laskutusaiheDialogAuki"
      width="88%"
      @close="(laskutusaiheDialogAuki = false)"
    >
      <FormSopimusLaskutusaihe
        v-model="sopimusNayta.laskutusaiheFormData"
        class="pa-4"
        :loading="laskutusaiheFormLoading"
        :laskutusjaksot="laskutusjaksoItems"
        :kirjoitusmoodi="sopimusNayta.laskutusaiheKirjoitusmoodi"
        :on-auki="laskutusaiheDialogAuki"
        @cancel="laskutusaiheDialogAuki = false"
        @submit="laskutusaiheSubmit"
        @close="(laskutusaiheDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Laskutusjakson muokkaus -->
    <CardDialog
      title="Muokkaa laskutusjaksoa"
      :dialog-auki="laskutusjaksoMuokkaaDialogAuki"
      @close="(laskutusjaksoMuokkaaDialogAuki = false)"
    >
      <FormSopimusLaskutusjaksoMuokkaa
        v-model="sopimusNayta.laskutusjaksoMuokkaaFormData"
        class="pa-4"
        :loading="laskutusjaksoMuokkaaFormLoading"
        @cancel="laskutusjaksoMuokkaaDialogAuki = false"
        @submit="laskutusjaksoMuokkaaSubmit"
        @close="(laskutusjaksoMuokkaaDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Laskutusjaksojen lisäys -->
    <CardDialog
      title="Lisää laskutusjaksoja"
      :dialog-auki="laskutusjaksoLisaaDialogAuki"
      @close="(laskutusjaksoLisaaDialogAuki = false)"
    >
      <FormSopimusLaskutusjaksoLisaa
        v-model="sopimusNayta.laskutusjaksoLisaaFormData"
        class="pa-4"
        :loading="laskutusjaksoLisaaFormLoading"
        :sopimus="sopimusNayta.sopimus"
        :on-auki="laskutusjaksoLisaaDialogAuki"
        @cancel="laskutusjaksoLisaaDialogAuki = false"
        @submit="laskutusjaksoLisaaSubmit"
        @close="(laskutusjaksoLisaaDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Päämiehen siirto -->
    <CardDialog
      title="Siirrä toiselle päämiehelle"
      :dialog-auki="paamiesSiirraDialogAuki"
      @close="(paamiesSiirraDialogAuki = false)"
    >
      <FormSopimusPaamiesSiirra
        v-model="sopimusNayta.paamiesSiirraFormData"
        class="pa-4"
        :loading="paamiesSiirraDialogLoading"
        :paamiehet="sopimusNayta.sopimus.siirtopaamiehet"
        :on-auki="paamiesSiirraDialogAuki"
        @cancel="paamiesSiirraDialogAuki = false"
        @submit="paamiesSiirraSubmit"
        @close="(paamiesSiirraDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Liitteiden avaaminen -->
    <MenuContext
      :onko-nakyvissa="sopimusNayta.menuContextLaskutusjaksonLiitteenAvausNakyvissa"
      :position-x="sopimusNayta.menuContextLaskutusjaksonLiitteenAvausX"
      :position-y="sopimusNayta.menuContextLaskutusjaksonLiitteenAvausY"
      :vaihtoehdot="sopimusNayta.menuContextLaskutusjaksonLiitteenAvausVaihtoehdot"
      @avaaLiite="avaaLaskutusjaksonLiite"
      @update:return-value="suljeMenuContextLaskutusjaksonLiite"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import _ from 'lodash'
import Laskurivi from '@/class/Laskurivi'
import Sopimus from '@/class/Sopimus'
import SopimusLaskutusaihe from '@/class/SopimusLaskutusaihe'
import {
  DataTableSopimusAsiakirja,
  FormSopimusHyvita,
  FormLiite,
  FormLiitteet,
  FormSopimusLaskusisalto,
  FormSopimusLaskutusaihe,
  FormSopimusLaskutusjaksoLisaa,
  FormSopimusLaskutusjaksoMuokkaa,
  FormSopimusPaamiesSiirra,
  SopimusYhteenveto,
  StatusText,
  TietueenPerustiedotCard,
} from '@/components'
import MenuContextMixin from '@/mixins/MenuContextMixin'
import {
  TableHeadersSopimusLaskusisalto,
  TableHeadersLaskutusaiheLainasopimus,
  TableHeadersLaskutusjakso,
  TableHeadersKassaAlennukset,
  TableHeadersSopimusLisatiedot,
} from '@/utils/tableHeaders'
import {
  Kirjoitusmoodit,
  MaksimiUploadTiedostoKoko,
} from '@/utils/constants'

export default {
  name: 'SopimusNayta',
  components: {
    DataTableSopimusAsiakirja,
    FormLiite,
    FormLiitteet,
    FormSopimusHyvita,
    FormSopimusLaskusisalto,
    FormSopimusLaskutusaihe,
    FormSopimusLaskutusjaksoLisaa,
    FormSopimusLaskutusjaksoMuokkaa,
    FormSopimusPaamiesSiirra,
    StatusText,
    SopimusYhteenveto,
    TietueenPerustiedotCard,
  },
  mixins: [MenuContextMixin],
  data () {
    return {
      tietueLataamassa: false,
      tietueLatausEpaonnistunut: false,
      asiakirjaDialogAuki: false,
      asiakirjaFormLoading: false,
      erapaivanSiirtoDialogAuki: false,
      erapaivanSiirtoFormLoading: false,
      hyvitaSopimustaDialogAuki: false,
      hyvitaSopimustaFormLoading: false,
      kirjoitusmoodit: Kirjoitusmoodit,
      laskusisaltoDialogAuki: false,
      laskusisaltoFormLoading: false,
      laskutusaiheDialogAuki: false,
      laskutusaiheFormLoading: false,
      laskutusjaksoLisaaDialogAuki: false,
      laskutusjaksoLisaaFormLoading: false,
      laskutusjaksoMuokkaaDialogAuki: false,
      laskutusjaksoMuokkaaFormLoading: false,
      laskutusjaksonLiiteDialogAuki: false,
      laskutusjaksonLiiteFormLoading: false,
      paamiesSiirraDialogAuki: false,
      paamiesSiirraDialogLoading: false,
      perinnanEstoDialogAuki: false,
      perinnanEstoFormLoading: false,
      tableHeadersKassaAlennukset: TableHeadersKassaAlennukset || [],
      tableHeadersSopimusLaskusisalto: TableHeadersSopimusLaskusisalto || [],
      tableHeadersSopimusLisatiedot: TableHeadersSopimusLisatiedot,
      tableLaskutusjaksoAloitussivu: 1,
      tableLaskusisaltoLoading: false,
      tableLaskutusjaksoLoading: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      sopimusNayta: state => state.sopimusNayta,
    }),

    laskurivitItems () {
      if (!this.sopimusNayta.sopimus.sopimuslaskusisalto_set) return []

      return this.sopimusNayta.sopimus.sopimuslaskusisalto_set.map(item => {
        return {
          id: item.id,
          koodi: item.koodi,
          selite: item.selite,
          alvp: item.alvp,
          alvtunnus_id: item.alvtunnus_id,
          ahinta: item.ahinta,
          maara: item.maara,
          veroton: item.veroton,
          vero: item.vero,
          verollinen: item.verollinen,
        }
      })
    },

    laskutusjaksoItems () {
      if (!this.sopimusNayta.sopimus.sopimuslaskutusjakso_set) return []
      if (this.sopimusNayta.sopimus.sopimuslaskutusjakso_set.length) {
        return this.sopimusNayta.sopimus.sopimuslaskutusjakso_set.map(laskutusjakso => {
          const laskutusjaksoId = laskutusjakso.id
          return {
            id: laskutusjaksoId,
            alkamispaiva: laskutusjakso.alkamispaiva,
            loppumispaiva: laskutusjakso.loppumispaiva,
            laskutusjakso: `${this.$date(laskutusjakso.alkamispaiva)} – ${this.$date(laskutusjakso.loppumispaiva)}`,
            selite: laskutusjakso.selite,
            tiedosto_set: laskutusjakso.tiedosto_set,
            subRows: laskutusjakso.sopimuslaskutusaihe_set.map(laskutusaihe => {
              return {
                id: laskutusaihe.id,
                ahinta: laskutusaihe.ahinta,
                alennusp: laskutusaihe.alennusp,
                alvp: laskutusaihe.alvp,
                alvtunnus: laskutusaihe.alvtunnus,
                erapaiva: this.$date(laskutusaihe.erapaiva),
                koodi: laskutusaihe.koodi,
                lasku: laskutusaihe.lasku,
                laskutusjakso: laskutusjaksoId,
                laskutuspaiva: laskutusaihe.laskutuspaiva,
                laskutuspaiva_display: this.$date(laskutusaihe.laskutuspaiva_display),
                maara: laskutusaihe.maara,
                selite: laskutusaihe.selite,
                sisalv: laskutusaihe.sisalv,
                vero: laskutusaihe.vero,
                verollinen: laskutusaihe.verollinen,
                veroton: laskutusaihe.veroton,
              }
            })
          }
        })
      } else if (this.sopimusNayta.sopimus.lainasopimus_sopimuslaskutusaihe_set.length) {
        return [{
          tiedosto_set: [],
          subRows: this.sopimusNayta.sopimus.lainasopimus_sopimuslaskutusaihe_set.map(laskutusaihe => {
              return {
                id: laskutusaihe.id,
                lasku: laskutusaihe.lasku,
                laskutusjakso: null,
                koodi: laskutusaihe.koodi,
                selite: laskutusaihe.selite,
                alennusp: laskutusaihe.alennusp,
                alvtunnus: laskutusaihe.alvtunnus,
                alvp: laskutusaihe.alvp,
                ahinta: laskutusaihe.ahinta,
                maara: laskutusaihe.maara,
                sisalv: laskutusaihe.sisalv,
                veroton: laskutusaihe.veroton,
                vero: laskutusaihe.vero,
                verollinen: laskutusaihe.verollinen,
              }
            })
        }]
      } else {
        return []
      }
    },

    tableHeadersLaskutusjakso () {
      if (this.sopimusNayta.sopimus.onko_lainasopimus) {
        return TableHeadersLaskutusaiheLainasopimus
      } else {
        return TableHeadersLaskutusjakso
      }
    },

    kassaAlennuksetItems () {
      if (!this.sopimusNayta.sopimus.kassaalennus_set) return []

      return this.sopimusNayta.sopimus.kassaalennus_set.map(item => {
        return {
          id: item.id,
          voimassa: item.voimassa,
          alennusp: item.alennusp,
          alennus: item.alennus,
        }
      })
    },

    sopimusAsiakirjaItems () {
      if (!this.sopimusNayta.sopimus.sopimusasiakirja_set) return []
      else return this.sopimusNayta.sopimus.sopimusasiakirja_set
    },

    laskuliitteetLkm () {
      return this.sopimusNayta.sopimus.laskuliite_set.length + this.sopimusNayta.sopimus.kirje_set.length
    },
    routeTitle () {
      if (this.sopimusNayta.sopimus.nro) {
        return `${this.$route.meta.title} ${this.sopimusNayta.sopimus.nro}`
      } else {
        return this.$route.meta.title
      }
    },
    muotoiltuSopimuksenLaskutusjakso () {
      return _.find(Sopimus.LASKUTUSJAKSOT, ['value', this.sopimusNayta.sopimus.laskutusjakso]).selite
    },
    muotoiltuLaskunLahetysTeksti () {
      return _.find(Sopimus.LASKUN_LAHETYS, ['value', this.sopimusNayta.sopimus.laskunlahetys]).selite
    },
    lisatiedotItems () {
      if (!this.sopimusNayta.sopimus.sopimuslisatieto_set) return []

      const items = this.sopimusNayta.sopimus.sopimuslisatieto_set.map(item => {
        return {
          key: item.key,
          value: item.value,
          luotu: item.luotu,
        }
      })

      return items
    },
    tasmaakoSeuraavaLaskutuspaiva () {
      return this.sopimusNayta.sopimus.tasmaakoSeuraavaLaskutuspaiva()
    }
  },
  watch: {
    '$route.params.id': {
      handler () {
        this.loadItem()
      }
    }
  },
  async created () {
    await this.loadItem()
  },
  methods: {
    async loadItem (silent = false) {
      try {
        if (!silent) this.tietueLataamassa = true
        await this.$store.dispatch('sopimusNayta/loadItem', this.$route)
        this.asetaLaskutusjaksotaulukonSivutus()
      } catch (e) {
        if (e.request && e.request.result && e.request.result.status === 404) {
          this.$naytaVirheilmoitus('Toistuvaa laskua ei löytynyt!')

          this.$router.replace({ name: 'error404' })
        } else {
          this.tietueLatausEpaonnistunut = true
          this.$oletusVirheenkasittely(e, 'Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.')
        }
      } finally {
        this.tietueLataamassa = false
      }
    },

    /**
     * Asettaa sivutuksen niin, että näytetään sivu, jolla on nykypäivää lähinnä oleva laskutusjakso.
     */
    async asetaLaskutusjaksotaulukonSivutus () {
      const sivunumero = await this.$store.dispatch('sopimusNayta/asetaLaskutusjaksotaulukonSivutus')
      this.$nextTick(() => {
        this.tableLaskutusjaksoAloitussivu = sivunumero
      })
    },

    avaaDialogAsiakirja () {
      this.$store.commit('sopimusNayta/setAsiakirjaFormData', {})
      this.asiakirjaDialogAuki = true
    },
    async asiakirjaSubmit () {
      try {
        this.asiakirjaFormLoading = true
        const onnistumisTeksti = await this.$store.dispatch('sopimusNayta/asiakirjaSubmit')
        this.$naytaOnnistumisilmoitus(onnistumisTeksti)
        this.asiakirjaDialogAuki = false
      } catch (e) {
          let virheviesti = ''
          if (e.name === this.$SallittuKokoYlitettyError.name) {
            virheviesti = this.$t('common:attachmentSizeExceeded', { filename: e.extra })
          } else {
            virheviesti = this.$t('common:attachmentSendingFailed')
          }

          this.$oletusVirheenkasittely(e, {
            virheviesti,
            tiedosto: this.$SallittuKokoYlitettyError.extra,
          })
      } finally {
        this.asiakirjaFormLoading = false
      }
    },
    avaaDialogHyvitaSopimusta () {
      this.hyvitaSopimustaDialogAuki = true
    },
    async hyvitaSopimustaSubmit () {
      try {
        this.hyvitaSopimustaFormLoading = true
        await this.$store.dispatch('sopimusNayta/hyvitaSopimustaSubmit')
        this.$naytaOnnistumisilmoitus('Hyvitys onnistui.' )
        this.hyvitaSopimustaDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.hyvitaSopimustaFormLoading = false
      }
    },
    async avaaDialogPaataSopimus () {
      const result = await this.$swal({
          title: 'Haluatko varmasti päättää laskutuksen?',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Päätä laskutus',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.paataSopimusSubmit()
    },
    async paataSopimusSubmit () {
      try {
        await this.$store.dispatch('sopimusNayta/paataSopimusSubmit')
        this.$naytaOnnistumisilmoitus('Laskutus päätetty.' )
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    async avaaDialogAktivoiSopimus () {
      const result = await this.$swal({
          title: 'Haluatko varmasti aktivoida laskutuksen?',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Aktivoi laskutus',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.aktivoiSopimusSubmit()
    },
    async aktivoiSopimusSubmit () {
      try {
        await this.$store.dispatch('sopimusNayta/aktivoiSopimusSubmit')
        this.$naytaOnnistumisilmoitus('Laskutus aktivoitu.' )
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    avaaDialogLaskutusjaksonLiite (laskutusjaksoId) {
      this.$store.commit('sopimusNayta/setLaskutusjaksonLiiteFormData', { laskutusjaksoId })
      this.laskutusjaksonLiiteDialogAuki = true
    },

    avaaDialogPerinnanEsto () {
      this.perinnanEstoDialogAuki = true
    },

    avaaDialogErapaivansiirto () {
      this.erapaivanSiirtoDialogAuki = true
    },

    avaaMenuContextLaskutusjaksoLiite (event, laskutusjakso) {
      this.$store.dispatch('sopimusNayta/avaaMenuContextLaskutusjaksoLiite', { event, laskutusjakso })
    },

    suljeMenuContextLaskutusjaksonLiite () {
      this.$store.commit('sopimusNayta/setMenuContextLaskutusjaksonLiitteenAvausNakyvissa', false)
    },

    async avaaLaskutusjaksonLiite (vaihtoehto) {
      try {
        const request = await this.$store.dispatch('sopimusNayta/avaaLaskutusjaksonLiite', vaihtoehto)
        this.$naytaTiedostonlataamisdialog({
          fileData: request.result.body,
          headers: request.result.headers,
          filename: vaihtoehto.title,
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },

    async laskutusjaksonLiiteSubmit () {
      try {
        this.laskutusjaksonLiiteFormLoading = true
        await this.$store.dispatch('sopimusNayta/laskutusjaksonLiiteSubmit')
        this.$naytaOnnistumisilmoitus('Liite onnistuneesti lisätty.' )
        this.laskutusjaksonLiiteDialogAuki = false
      } catch (e) {
        let virheviesti = ''

        if (e.name === this.$SallittuKokoYlitettyError.name) {
          virheviesti = this.$t('common:attachmentSizeExceeded', { filename: this.laskutusjaksonLiiteFormData.tiedosto.name })
        } else {
          virheviesti = this.$t('common:attachmentSendingFailed')
        }
        this.$oletusVirheenkasittely(e, virheviesti)
      } finally {
        this.laskutusjaksonLiiteFormLoading = false
      }
    },
    siirrySopimuksenMuokkaukseen () {
      this.$store.dispatch('sopimusNayta/siirrySopimuksenMuokkaukseen', this.$router)
    },
    muotoileMaksuaika (maksuaika) {
      return Number(maksuaika) ? maksuaika + ' pv' : maksuaika
    },
    muokkaaLaskutusjaksoa (laskutusjakso) {
      this.$store.dispatch('sopimusNayta/muokkaaLaskutusjaksoa', laskutusjakso)
      this.laskutusjaksoMuokkaaDialogAuki = true
    },
    async laskutusjaksoMuokkaaSubmit () {
      try {
        this.laskutusjaksoMuokkaaFormLoading = true
        await this.$store.dispatch('sopimusNayta/laskutusjaksoMuokkaaSubmit')
        this.$naytaOnnistumisilmoitus('Laskutusjakso päivitetty.')
        this.laskutusjaksoMuokkaaDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.laskutusjaksoMuokkaaFormLoading = false
      }
    },
    async avaaLaskutaLaskutusjaksoDialog (id) {
      const result = await this.$swal({
          title: 'Haluatko varmasti laskuttaa laskutusjakson?',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Laskuta laskutusjakso',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.laskutaLaskutusjakso(id)
    },
    async laskutaLaskutusjakso (id) {
      try {
        await this.$store.dispatch('sopimusNayta/laskutaLaskutusjakso', id)
        this.$naytaOnnistumisilmoitus('Laskutusjakso laskutettu.')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    avaaLaskutusjaksoLisaaDialog () {
      this.laskutusjaksoLisaaDialogAuki = true
    },
    async laskutusjaksoLisaaSubmit () {
       try {
        this.laskutusjaksoLisaaFormLoading = true
        this.tableLaskutusjaksoLoading = true
        await this.$store.dispatch('sopimusNayta/laskutusjaksoLisaaSubmit')
        this.$naytaOnnistumisilmoitus('Laskutusjakso(t) luotu.')
        this.laskutusjaksoLisaaDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.laskutusjaksoLisaaFormLoading = false
        this.tableLaskutusjaksoLoading = false
      }
    },
    lisaaLaskusisalto () {
      this.$store.dispatch('sopimusNayta/lisaaLaskusisalto')
      this.laskusisaltoDialogAuki = true
      this.$nextTick(() => {
        this.$refs.laskusisaltoDialog.$refs.formSopimusLaskusisalto.resetValidation()
      })
    },
    muokkaaLaskusisaltoa (laskusisalto) {
      this.$store.dispatch('sopimusNayta/muokkaaLaskusisaltoa', laskusisalto)
      this.laskusisaltoDialogAuki = true
    },
    async laskusisaltoSubmit () {
      try {
        if (!this.$refs.laskusisaltoDialog.validate()) {
          this.$naytaVirheilmoitus('Tarkista lomakkeen kenttien sisältö!')
          return
        } 
        this.laskusisaltoFormLoading = true
        this.tableLaskusisaltoLoading = true
        this.$store.dispatch('sopimusNayta/laskusisaltoSubmit')
        this.$naytaOnnistumisilmoitus('Laskurivi tallennettu.')
        this.laskusisaltoDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.laskusisaltoFormLoading = false
        this.tableLaskusisaltoLoading = false
      }
    },
    async avaaLaskusisaltoPoistaDialog (id) {
      const result = await this.$swal({
          title: 'Haluatko varmasti poistaa laskurivin?',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Poista laskurivi',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.laskutusisaltoPoista(id)
    },
    async laskutusisaltoPoista (id = null) {
      try {
        this.tableLaskusisaltoLoading = true
        this.$store.dispatch('sopimusNayta/laskutusisaltoPoista', id)
        this.$naytaOnnistumisilmoitus('Laskurivi poistettu.')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.tableLaskusisaltoLoading = false
      }
    },
    lisaaLaskutusaihe (laskutusjakso) {
      this.$store.dispatch('sopimusNayta/lisaaLaskutusaihe', laskutusjakso)
      this.laskutusaiheDialogAuki = true
    },
    muokkaaLaskutusaihetta (laskutusaihe) {
      this.$store.dispatch('sopimusNayta/muokkaaLaskutusaihetta', laskutusaihe)
      this.laskutusaiheDialogAuki = true
    },
    async laskutusaiheSubmit () {
      try {
        this.laskutusaiheFormLoading = true
        this.tableLaskutusjaksoLoading = true
        this.$store.dispatch('sopimusNayta/laskutusaiheSubmit')
        this.$naytaOnnistumisilmoitus('Laskutusaihe tallennettu.')
        this.laskutusaiheDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.laskutusaiheFormLoading = false
        this.tableLaskutusjaksoLoading = false
      }
    },
    async avaaLaskutusaihePoistaDialog (id) {
      const result = await this.$swal({
          title: 'Haluatko varmasti poistaa laskutusaiheen?',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Poista laskutusaihe',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.laskutusaihePoista(id)
    },
    async laskutusaihePoista (id = null) {
      try {
        this.tableLaskutusjaksoLoading = true
        this.$store.dispatch('sopimusNayta/laskutusaihePoista', id)
        const onnistumisviesti = id ? 'Laskutusaihe poistettu.' : 'Laskutusaiheet poistettu.'
        this.$naytaOnnistumisilmoitus(onnistumisviesti)
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.tableLaskutusjaksoLoading = false
      }
    },
    async avaaLaskutusaiheetPaivitaDialog () {
      const result = await this.$swal({
          title: 'Haluatko varmasti päivittää laskutusaiheet?',
          text: 'Toiminto poistaa olemassa olevat laskuttamattomat laskutusaiheet ja niihin liittyvät laskutusjaksot. Tämän jälkeen laskutusaiheet ja -jaksot luodaan uudelleen toistuvalle laskulle määritettyjen laskurivien perusteella. Huom. tämä asettaa seuraavan laskutuspäivän/eräpäivän viimeisimmän laskutetun erän perusteella.',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Päivitä laskutusaiheet',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.laskutusaiheetPaivita()
    },
    async laskutusaiheetPaivita () {
      try {
        this.tableLaskutusjaksoLoading = true
        this.$store.dispatch('sopimusNayta/laskutusaiheetPaivita')
        this.$naytaOnnistumisilmoitus('Laskutusaiheet päivitetty')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.tableLaskutusjaksoLoading = false
      }
    },
    async avaaLaskutusaihePoistaLaskuttamattomatDialog () {
      const result = await this.$swal({
          title: 'Haluatko varmasti poistaa kaikki laskuttamattomat laskutusaiheet?',
          text: 'Toiminto poistaa olemassa olevat laskuttamattomat laskutusaiheet ja niihin liittyvät laskutusjaksot.',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Poista laskutusaiheet',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.laskutusaihePoista()
    },
    avaaDialogPaamiesSiirra () {
      this.paamiesSiirraDialogAuki = true
    },
    async paamiesSiirraSubmit () {
      this.paamiesSiirraDialogLoading = true

      try {
        this.$store.dispatch('sopimusNayta/paamiesSiirraSubmit')
        this.$naytaOnnistumisilmoitus('Päämies vaihdettu.')
        this.paamiesSiirraDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.paamiesSiirraDialogLoading = false
      }
    }
  },
}

</script>

<style lang="scss" scoped>

  .columnSet {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    &--toimeksianto {
      flex-direction: row;

      > .column {
        &:first-child {
          flex: 0 0 70%;
          background: red;
        }

        &:last-child {
          flex: 1 1 auto;
          background: blue;
        }
      }
    }
  }

</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sopimus-tallenna grey lighten-2" },
    [
      _c(
        "div",
        { staticClass: "sticky" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "v-toolbar--sticky pl-6",
              attrs: { color: "primary", flat: "", tabs: "", dark: true },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.routeTitle) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "px-6 py-12 main-div" },
        [
          _vm.luotuSopimus.id || _vm.muokattuSopimus.id
            ? [
                _c(
                  "v-container",
                  { staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pa-6", attrs: { justify: "center" } },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c("StatusIcon", {
                                      attrs: {
                                        icon: "repeat-invoice",
                                        size: "large",
                                        "hover-icon": "open-in-new",
                                        "bg-color": "primary",
                                        "event-listener": on,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.$router.push({
                                            name: "sopimus-nayta",
                                            params: {
                                              id: _vm.luotuSopimus.id
                                                ? _vm.luotuSopimus.id
                                                : _vm.muokattuSopimus.id,
                                            },
                                          })
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("common:openRepeatingInvoice")
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2936846432
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "pa-6", attrs: { justify: "center" } },
                      [
                        _c(
                          "span",
                          { staticClass: "title" },
                          [
                            _vm._v(_vm._s(_vm.sopimusTallennettuTeksti) + " "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "sopimus-nayta",
                                    params: {
                                      id: _vm.luotuSopimus.id
                                        ? _vm.luotuSopimus.id
                                        : _vm.muokattuSopimus.id,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.luotuSopimus.id
                                      ? _vm.luotuSopimus.nro
                                      : _vm.muokattuSopimus.nro
                                  )
                                ),
                              ]
                            ),
                            _vm._v(". "),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-row",
                      { staticClass: "pa-6", attrs: { justify: "center" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            attrs: { large: "", to: { name: "sopimus-selaa" } },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("common:contractSave.returnSearch")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-2",
                            attrs: { large: "", color: "success" },
                            on: { click: _vm.palautaAlkutilaan },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "common:contractSave.createNewRepeatingInvoice"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  [
                    _c("FormSopimus", {
                      ref: "formSopimus",
                      attrs: {
                        "muokkaamassa-sopimusta": _vm.muokkaamassaSopimusta,
                      },
                      on: {
                        "avaa-dialog-asiakas": _vm.avaaDialogAsiakas,
                        "avaa-dialog-asiakas-muistiinpanot":
                          _vm.avaaDialogAsiakasMuistiinpanot,
                        "avaa-dialog-paamies": _vm.avaaDialogPaamies,
                        muokkaus: function ($event) {
                          _vm.naytaPoistumisvahvistus = true
                        },
                      },
                      model: {
                        value: _vm.sopimus,
                        callback: function ($$v) {
                          _vm.sopimus = $$v
                        },
                        expression: "sopimus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12",
                    attrs: { justify: "center" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-5",
                        attrs: { large: "", color: "success" },
                        on: { click: _vm.tallennaSopimus },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$route.params.muokattavaSopimus
                                ? _vm.$t("common:saveChanges")
                                : _vm.$t(
                                    "common:contractSave.createRepeatingInvoice"
                                  )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.sopimus.paamies.id
              ? _vm.$t("common:contractSave.editCreditor")
              : _vm.$t("common:newCreditor"),
            "dialog-auki": _vm.paamiesDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "1024",
          },
          on: {
            close: function ($event) {
              _vm.paamiesDialogAuki = false
            },
          },
        },
        [
          _c("FormPaamies", {
            attrs: {
              "kaare-objekti": _vm.sopimus,
              "on-auki": _vm.paamiesDialogAuki,
            },
            on: {
              close: function ($event) {
                _vm.paamiesDialogAuki = false
              },
              paamiesTallennettu: _vm.paivitaPaamies,
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.sopimus.asiakas.id
              ? _vm.$t("common:editCustomer")
              : _vm.$t("common:newCustomer"),
            "dialog-auki": _vm.asiakasDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "1024",
          },
          on: {
            close: function ($event) {
              _vm.asiakasDialogAuki = false
            },
          },
        },
        [
          _c("FormAsiakas", {
            attrs: {
              "kaare-objekti": _vm.sopimus,
              "kenttien-valinnat": _vm.kenttienValinnat,
              "on-auki": _vm.asiakasDialogAuki,
            },
            on: {
              close: function ($event) {
                _vm.asiakasDialogAuki = false
              },
              asiakasTallennettu: _vm.paivitaAsiakas,
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Asiakkaaseen liittyvät muistiinpanot",
            "dialog-auki": _vm.asiakasMuistiinpanotDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4 pb-12",
            width: "1024",
          },
          on: {
            close: function ($event) {
              _vm.asiakasMuistiinpanotDialogAuki = false
            },
          },
        },
        [
          _c("AsiakasMuistiinpanotDatatable", {
            attrs: { items: _vm.muistiinpanoItems },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="sopimus-selaa">
    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasSopimus
        v-else
        v-model="selectedItem.item"
        @close-button-click="setShowOffCanvas"
        @messageSubmit="refresh"
        @msg-mark-as-unread="refresh"
        @msg-mark-as-read="refresh"
      />
    </v-navigation-drawer>

    <!-- Top bar -->
    <ViewSelaaTopBarBase
      :pikahaun-placeholder-teksti="$t('common:collectionBrowse.quickSearch')"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Tabs -->
    <ViewSelaaTabs
      :vuex-module-name="vuexModuleName"
    />

    <!-- Advanced search -->
    <TransitionSlidePanel>
      <div
        v-show="searchPanelOpen"
        class="pa-1 primary darken-1 shadow--inset"
      >
        <InfoValittuValilehti
          :current-tab="currentTab"
        />
        <FormSearchSopimus v-model="searchTerms" />
      </div>
    </TransitionSlidePanel>

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      :item-partitiivi="$t('common:contractBrowse.repeatingInvoice')"
      :lataa-csv-btn-config="{ csvData, csvLabels, csvTitle }"
      :uusi-btn-config="{
        toName: 'sopimus-tallenna',
        itemName: 'toistuva lasku',
        permission: $permission.checkSinglePerm('onlinerestapi.onlinerestapi_sopimus_uusi'),
      }"
    />

    <!-- Data Table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :selected-item="selectedItem.item"
          :fade="loading"
          :yellow="item.onko_myohassa"
          :custom-contextmenu="(true)"
          @click="setSelectedItem({ item })"
          @contextmenu.prevent="avaaMenuContext($event, item)"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('open-in-new')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="$router.push({ name: 'sopimus-nayta', params: { id: item.id } })"
                />
              </template>
              <template v-slot:default>
                <span>{{ $t('common:openRepeatingInvoice') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td class="text-no-wrap">
            {{ item.nro }}
          </td>
          <td>
            {{ item.paamies.nimi }} <template v-if="item.paamies.id">
              ({{ item.paamies.id }})
            </template>
          </td>
          <td class="max-width">
            {{ item.asiakas.nimi }} <template v-if="item.asiakas.nro">
              ({{ item.asiakas.nro }})
            </template>
          </td>
          <td class="text-no-wrap">
            {{ item.tila.selite }}
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="text-no-wrap text-right">
            {{ $date(item.alkamispaiva) }}
          </td>
          <td class="text-no-wrap text-right">
            {{ $date(item.loppumispaiva) }}
          </td>
          <td class="text-no-wrap">
            {{ item.viitteemme }}
          </td>
          <td class="text-no-wrap">
            {{ item.viitteenne }}
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <TyhjaTaulukkoOtsikko v-if="tyhjaTaulukko"
      :otsikko="$t('contractSave.youHaveNoRepeatingInvoices')"
    >
    </TyhjaTaulukkoOtsikko>
    <ToolbarTyhjaTaulukko v-if="tyhjaTaulukko"
      ikoni="library_add"
      taustavari="success"
      :teksti="$t('contractSave.newRepeatingInvoice')"
      :infoteksti="$t('contractSave.noRepeatingInvoiceAddInfo')"
      :naytaAina="true"
      :uusi-btn-config="{
        toName: 'sopimus-tallenna',
        itemName: 'toistuva lasku',
        permission: $permission.checkSinglePerm('onlinerestapi.onlinerestapi_sopimus_uusi'),
      }"
    >
    </ToolbarTyhjaTaulukko>
    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <!-- Välilehti-dialog -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />
  </div>
</template>

<script>

import {
  FormSearchSopimus,
  InfoValittuValilehti,
  OffCanvasSopimus,
  Pagination,
  ToolbarTyhjaTaulukko,
  TyhjaTaulukkoOtsikko,
  TransitionSlidePanel,
  ViewSelaaTabs,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import {
  TableHeadersSopimukset,
  TableHeadersSopimuksetTiivis,
} from '@/utils/tableHeaders'

export default {
  name: 'SopimusSelaa',
  components: {
    FormSearchSopimus,
    InfoValittuValilehti,
    OffCanvasSopimus,
    Pagination,
    TransitionSlidePanel,
    ViewSelaaTabs,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
    ToolbarTyhjaTaulukko,
    TyhjaTaulukkoOtsikko,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      menuContextValilehtiRouteName: 'sopimus-nayta'
    }
  },
  computed: {
    tableHeaders () {
      return this.tiivisNakyma ? TableHeadersSopimuksetTiivis : TableHeadersSopimukset
    },
    csvLabels () {
      return {
          nro: { title: this.$t('common:invoiceNo') },
          ...(!this.vainYksiPaamies && { paamies: { title: this.$t('common:creditor') } }),
          asiakas: { title: this.$t('common:customer') },
          tila: { title: this.$t('common:status') },
          id: { title:  this.$sovellusIdOtsake },
          alkamispaiva: { title: this.$t('common:startingDate') },
          loppumispaiva: { title: this.$t('common:endingDate') },
          viitteemme: { title: this.$t('common:ourReference') },
          viitteenne: { title: this.$t('common:yourReference') },
      }
    },
    csvData () {
      return this.items.map(item => {
        return {
            nro: item.nro,
            ...(!this.vainYksiPaamies && { paamies: `${item.paamies.nimi} (${item.paamies.id})` }),
            id: item.id,
            asiakas: item.asiakas.nimi,
            tila: item.tila.selite,
            alkamispaiva: item.alkamispaiva,
            loppumispaiva: item.loppumispaiva || '',
            viitteemme: item.viittemme || '',
            viitteenne: item.viittenne || '',
        }
      })
    },
    csvTitle () {
      return this.$t('common:contractBrowse.repeatingInvoiceSearch') + this.$dateFnsFormat(new Date(), 'YYYY-MM-DD-HHmm-ss')
    },
  },
  methods: {
    lisaaViewKohtaisetHaunSuodattimet (query) {
      if (this.searchTerms.sopimusnumero) query.append('nro', this.searchTerms.sopimusnumero)
      if (typeof this.searchTerms.tila === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.tila)
      }
      if (this.searchTerms.asiakas) query.append('asiakas', this.searchTerms.asiakas)
      if (this.searchTerms.paamies.length) {
        for (const pm of this.searchTerms.paamies) {
          query.append('paamiehet', pm.id)
        }
      }
      if (this.searchTerms.viite) query.append('viite', this.searchTerms.viite)
      if (this.searchTerms.viitteemme) query.append('viitteemme', this.searchTerms.viitteemme)
      if (this.searchTerms.viitteenne) query.append('viitteenne', this.searchTerms.viitteenne)
      if (typeof this.searchTerms.voimassa === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.voimassa)
      }
      if (this.searchTerms.alkamispaivaAlkaen) query.append('alkamispaiva_after', this.searchTerms.alkamispaivaAlkaen)
      if (this.searchTerms.alkamispaivaPaattyen) query.append('alkamispaiva_before', this.searchTerms.alkamispaivaPaattyen)
      if (this.searchTerms.loppumispaivaAlkaen) query.append('loppumispaiva_after', this.searchTerms.loppumispaivaAlkaen)
      if (this.searchTerms.loppumispaivaPaattyen) query.append('loppumispaiva_before', this.searchTerms.loppumispaivaPaattyen)
      if (this.searchTerms.maksuehto) query.append('maksuehto', this.searchTerms.maksuehto)
      if (this.searchTerms.lainatyyppi) query.append('lainatyyppi', this.searchTerms.lainatyyppi)
      if (this.searchTerms.sopimuslaji) query.append('laji', this.searchTerms.sopimuslaji)
      if (this.searchTerms.sopimusryhma) query.append('ryhma', this.searchTerms.sopimusryhma)

      return query
    },
    closeSearchPanel () {
      this.searchTerms = { paamies: [] }
      this.searchPanelOpen = false
    },
  },
}

</script>

<style lang="scss" scoped>

</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sopimus-nayta" },
    [
      _c(
        "ViewTopBarBase",
        { attrs: { title: _vm.routeTitle } },
        [
          _c("v-menu", {
            attrs: { bottom: "", left: "", "nudge-bottom": "40" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        { attrs: { color: "success", depressed: "" } },
                        on
                      ),
                      [
                        _vm._v(" " + _vm._s(_vm.$t("common:actions")) + " "),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v(" more_vert "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          { on: { click: _vm.siirrySopimuksenMuokkaukseen } },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("edit")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "common:sopimusNayta.editControlInformation"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.avaaDialogAsiakirja } },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("attach_file")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("common:sopimusNayta.addDocuments")
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.avaaDialogHyvitaSopimusta } },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("euro")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("common:sopimusNayta.makeRefund")
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm.sopimusNayta.sopimus.tila.value === "A"
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.avaaDialogPaataSopimus } },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("block")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "common:sopimusNayta.stopBilling"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm.sopimusNayta.sopimus.tila.value === "P"
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.avaaDialogAktivoiSopimus } },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("start")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "common:sopimusNayta.activateBilling"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.avaaDialogPaamiesSiirra } },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("redo")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "common:sopimusNayta.transferToAnotherCreditor"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm.tietueLataamassa
        ? _c("Spinner")
        : _vm.tietueLatausEpaonnistunut
        ? _c("CardPahoittelu")
        : [
            _c("v-container", { staticClass: "pa-2", attrs: { fluid: "" } }, [
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c("TietueenPerustiedotCard", {
                    attrs: {
                      "card-class": _vm.sopimusNayta.sopimus.onko_myohassa
                        ? "yellow lighten-5"
                        : null,
                      paamies: _vm.sopimusNayta.sopimus.paamies,
                      vastapuoli: _vm.sopimusNayta.sopimus.asiakas,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "col2",
                        fn: function () {
                          return [
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListItem", {
                                  attrs: {
                                    "label-class": "defList__label--emphasized",
                                    "value-class": "font-weight-bold",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common:contractNumber"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.sopimusNayta.sopimus.nro
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.sopimusNayta.sopimus
                                                        .nro
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListTooltipItem", {
                                  attrs: {
                                    tooltip: _vm.$t(
                                      "common:infotekstit.sopimusInfo.status"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common:status")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.sopimusNayta.sopimus.tila &&
                                      _vm.sopimusNayta.sopimus.ikoni_ja_vari
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _c("StatusText", {
                                                  attrs: {
                                                    color:
                                                      _vm.sopimusNayta.sopimus
                                                        .ikoni_ja_vari.vari,
                                                    text: _vm.sopimusNayta
                                                      .sopimus.tila.selite,
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$sovellusIdOtsake) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.sopimusNayta.sopimus.id
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("DefListTooltipItem", {
                                  attrs: {
                                    tooltip: _vm.$t(
                                      "common:infotekstit.sopimusInfo.reference"
                                    ),
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "common:invoiceReference"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.sopimusNayta.sopimus.viite
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _vm.sopimusNayta.sopimus.viitteemme
                                  ? _c("DefListTooltipItem", {
                                      attrs: {
                                        tooltip: _vm.$t(
                                          "common:infotekstit.sopimusInfo.ourReference"
                                        ),
                                        "value-class": "text-wrap",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:ourReference"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _c("PerustietoTooltip", {
                                                  attrs: {
                                                    teksti:
                                                      _vm.sopimusNayta.sopimus
                                                        .viitteemme,
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1374336838
                                      ),
                                    })
                                  : _vm._e(),
                                _vm.sopimusNayta.sopimus.viitteenne
                                  ? _c("DefListTooltipItem", {
                                      attrs: {
                                        tooltip: _vm.$t(
                                          "common:infotekstit.sopimusInfo.yourReference"
                                        ),
                                        "value-class": "text-wrap",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:yourReference"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _c("PerustietoTooltip", {
                                                  attrs: {
                                                    teksti:
                                                      _vm.sopimusNayta.sopimus
                                                        .viitteenne,
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        99331583
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "col3",
                        fn: function () {
                          return [
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListTooltipItem", {
                                  attrs: {
                                    tooltip: _vm.$t(
                                      "common:infotekstit.sopimusInfo.startingDate"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common:startingDate")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.sopimusNayta.sopimus.alkamispaiva
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$date(
                                                        _vm.sopimusNayta.sopimus
                                                          .alkamispaiva
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListTooltipItem", {
                                  attrs: {
                                    tooltip: _vm.$t(
                                      "common:infotekstit.sopimusInfo.endingDate"
                                    ),
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("common:endingDate")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm.sopimusNayta.sopimus.loppumispaiva
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$date(
                                                      _vm.sopimusNayta.sopimus
                                                        .loppumispaiva
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("common:validForNow")
                                                  )
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("DefListTooltipItem", {
                                  attrs: {
                                    tooltip: _vm.$t(
                                      "common:infotekstit.sopimusInfo.invoicePeriod"
                                    ),
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("common:invoicingPeriod")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.muotoiltuSopimuksenLaskutusjakso
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _vm.sopimusNayta.sopimus.laskutuspaiva
                                  ? _c("DefListTooltipItem", {
                                      attrs: {
                                        tooltip: _vm.$t(
                                          "common:infotekstit.sopimusInfo.invoiceDate"
                                        ),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:invoicingDate"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:sopimusNayta.monthDay",
                                                        {
                                                          invoicingDay:
                                                            _vm.sopimusNayta
                                                              .sopimus
                                                              .laskutuspaiva,
                                                        }
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1768415725
                                      ),
                                    })
                                  : _vm.sopimusNayta.sopimus.erapaiva
                                  ? _c("DefListTooltipItem", {
                                      attrs: {
                                        tooltip: _vm.$t(
                                          "common:infotekstit.sopimusInfo.dueDate"
                                        ),
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("common:dueDate")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "value",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "common:sopimusNayta.monthDay",
                                                      {
                                                        invoicingDay:
                                                          _vm.sopimusNayta
                                                            .sopimus.erapaiva,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    })
                                  : _vm._e(),
                                _c("DefListTooltipItem", {
                                  attrs: {
                                    tooltip: _vm.$t(
                                      "common:infotekstit.sopimusInfo.paymentTerm"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common:termsOfPayment"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.sopimusNayta.sopimus.maksuehto
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.muotoileMaksuaika(
                                                        _vm.sopimusNayta.sopimus
                                                          .maksuehto
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm.sopimusNayta.sopimus.laskutuspaiva &&
                                _vm.sopimusNayta.sopimus.seuraava_laskutuspaiva
                                  ? _c("DefListTooltipItem", {
                                      attrs: {
                                        tooltip: _vm.$t(
                                          "common:infotekstit.sopimusInfo.nextInvoiceDate"
                                        ),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:nextInvoiceDate"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$date(
                                                        _vm.sopimusNayta.sopimus
                                                          .seuraava_laskutuspaiva
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2212434518
                                      ),
                                    })
                                  : _vm.sopimusNayta.sopimus.erapaiva &&
                                    _vm.sopimusNayta.sopimus.seuraava_erapaiva
                                  ? _c("DefListTooltipItem", {
                                      attrs: {
                                        tooltip: _vm.$t(
                                          "common:infotekstit.sopimusInfo.nextInvoiceDate"
                                        ),
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("common:nextDueDate")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "value",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$date(
                                                      _vm.sopimusNayta.sopimus
                                                        .seuraava_erapaiva
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "col4",
                        fn: function () {
                          return [
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListTooltipItem", {
                                  attrs: {
                                    tooltip: _vm.$t(
                                      "common:infotekstit.sopimusInfo.letterhead"
                                    ),
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "common:sopimusNayta.invoiceBase"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm.sopimusNayta.sopimus.kirjepohja
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sopimusNayta.sopimus
                                                      .kirjepohja.id
                                                  ) +
                                                    ": " +
                                                    _vm._s(
                                                      _vm.sopimusNayta.sopimus
                                                        .kirjepohja.nimi
                                                    )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("DefListTooltipItem", {
                                  attrs: {
                                    tooltip: _vm.$t(
                                      "common:infotekstit.sopimusInfo.invoiceSending"
                                    ),
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "common:sopimusNayta.invoiceSend"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.muotoiltuLaskunLahetysTeksti
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("common:lateInterest")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm.sopimusNayta.sopimus
                                            .viivastyskorko
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sopimusNayta.sopimus
                                                      .viivastyskorko
                                                  )
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "common:sopimusNayta.accordingToKsl"
                                                    )
                                                  )
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _vm.sopimusNayta.sopimus.laji
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:typeOfContract"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sopimusNayta.sopimus
                                                        .laji
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3189236823
                                      ),
                                    })
                                  : _vm._e(),
                                _vm.sopimusNayta.sopimus.laskunteksti
                                  ? _c("DefListTooltipItem", {
                                      attrs: {
                                        tooltip: _vm.$t(
                                          "common:infotekstit.sopimusInfo.invoiceText"
                                        ),
                                        "label-class": "text-wrap",
                                        "value-class": "text-wrap",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:invoiceText"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _c("PerustietoTooltip", {
                                                  attrs: {
                                                    teksti:
                                                      _vm.sopimusNayta.sopimus
                                                        .laskunteksti,
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3903079232
                                      ),
                                    })
                                  : _vm._e(),
                                _vm.sopimusNayta.sopimus
                                  .kassaalennus_prosentti &&
                                _vm.sopimusNayta.sopimus
                                  .kassaalennus_prosentti !== "0.00"
                                  ? [
                                      _c("DefListTooltipItem", {
                                        attrs: {
                                          tooltip: _vm.$t(
                                            "common:infotekstit.sopimusInfo.cashDiscountPercent"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common:sopimusNayta.cashDiscountPercent"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.sopimusNayta.sopimus
                                                          .kassaalennus_prosentti
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1139748411
                                        ),
                                      }),
                                      _c("DefListTooltipItem", {
                                        attrs: {
                                          tooltip: _vm.$t(
                                            "common:infotekstit.sopimusInfo.cashDiscountPaymentPeriod"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common:sopimusNayta.cashSalePaymentPeriod"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.muotoileMaksuaika(
                                                          _vm.sopimusNayta
                                                            .sopimus
                                                            .kassaalennus_maksuaika
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2922894856
                                        ),
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  !_vm.tasmaakoSeuraavaLaskutuspaiva
                    ? _c("CardVaroitus", {
                        attrs: {
                          teksti:
                            _vm.sopimusNayta.sopimus.seuraavaLaskutuspaivaVaroitus(),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", lg: "7" } }, [
                        _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pa-6" },
                              [
                                _c("CardTitle", {
                                  attrs: { text: "Laskurivit" },
                                }),
                                _c("v-data-table", {
                                  attrs: {
                                    items: _vm.laskurivitItems,
                                    headers:
                                      _vm.tableHeadersSopimusLaskusisalto,
                                    "hide-default-footer":
                                      _vm.laskurivitItems.length <= 5,
                                    "no-data-text": "Ei laskurivejä",
                                    dense: _vm.user.tiivisNakyma,
                                    loading: _vm.tableLaskusisaltoLoading,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "top",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-toolbar",
                                              {
                                                attrs: {
                                                  flat: "",
                                                  short: "",
                                                  dense: _vm.user.tiivisNakyma,
                                                },
                                              },
                                              [
                                                _c("TooltipInfo", {
                                                  attrs: {
                                                    "icon-color": "primary",
                                                    text: _vm.$t(
                                                      "common:infotekstit.sopimusInfo.invoiceLines"
                                                    ),
                                                  },
                                                }),
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.lisaaLaskusisalto,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { left: "" } },
                                                      [_vm._v(" add ")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common:sopimusNayta.addInvoiceRow"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "item",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c("TableRow", [
                                              _c(
                                                "td",
                                                { staticClass: "text-no-wrap" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.koodi) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "max-width" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.selite) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.alvp) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSumToFixed2"
                                                        )(item.ahinta)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$muotoileDesimaaliluku(
                                                          item.maara
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSumToFixed2"
                                                        )(item.veroton)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSumToFixed2"
                                                        )(item.vero)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSumToFixed2"
                                                        )(item.verollinen)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-tooltip", {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                      icon: "",
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.muokkaaLaskusisaltoa(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-pencil "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "default",
                                                          fn: function () {
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common:sopimusNayta.editInvoiceRow"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                  _c("v-tooltip", {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                      icon: "",
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.avaaLaskusisaltoPoistaDialog(
                                                                            item.id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " delete "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "default",
                                                          fn: function () {
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common:sopimusNayta.deleteInvoiceRow"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      _vm.laskurivitItems.length
                                        ? {
                                            key: "body.append",
                                            fn: function () {
                                              return [
                                                _c("TableRow", [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                      attrs: { colspan: "5" },
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("Yhteensä"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-right",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              _vm.sopimusNayta
                                                                .sopimus
                                                                .sopimuslaskusisalto_yhteenveto
                                                                .veroton
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              _vm.sopimusNayta
                                                                .sopimus
                                                                .sopimuslaskusisalto_yhteenveto
                                                                .vero
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              _vm.sopimusNayta
                                                                .sopimus
                                                                .sopimuslaskusisalto_yhteenveto
                                                                .verollinen
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c("td"),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", lg: "5" } }, [
                        _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pa-6" },
                              [
                                _c("CardTitle", {
                                  attrs: { text: "Yhteenveto" },
                                }),
                                _c("SopimusYhteenveto", {
                                  attrs: { sopimus: _vm.sopimusNayta.sopimus },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pa-6" },
                              [
                                _c("CardTitle", {
                                  attrs: {
                                    text: _vm.sopimusNayta.sopimus
                                      .onko_lainasopimus
                                      ? "Laskutusaiheet"
                                      : "Laskutusjaksot",
                                  },
                                }),
                                _c("v-data-table", {
                                  ref: "tableLaskutusjakso",
                                  attrs: {
                                    items: _vm.laskutusjaksoItems,
                                    headers: _vm.tableHeadersLaskutusjakso,
                                    "hide-default-footer":
                                      _vm.laskutusjaksoItems.length <= 5,
                                    "items-per-page": 5,
                                    loading: _vm.tableLaskutusjaksoLoading,
                                    "footer-props": {
                                      itemsPerPageText:
                                        "Laskutusjaksoja per sivu",
                                      itemsPerPageOptions: [3, 5, 10, -1],
                                    },
                                    "no-data-text": "Ei laskutusjaksoja",
                                    dense: "",
                                    page: _vm.tableLaskutusjaksoAloitussivu,
                                    "disable-sort": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "top",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-toolbar",
                                            {
                                              attrs: {
                                                flat: "",
                                                short: "",
                                                dense: _vm.user.tiivisNakyma,
                                              },
                                            },
                                            [
                                              _c("span"),
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                    disabled:
                                                      !_vm.sopimusNayta.sopimus
                                                        .laskutettavissa,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.avaaLaskutusjaksoLisaaDialog,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [_vm._v(" add ")]
                                                  ),
                                                  _vm._v(
                                                    " Lisää laskutusjaksoja "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.avaaLaskutusaihePoistaLaskuttamattomatDialog,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        small: "",
                                                      },
                                                    },
                                                    [_vm._v(" delete ")]
                                                  ),
                                                  _vm._v(
                                                    " Poista laskuttamattomat laskutusaiheet "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                    disabled:
                                                      !_vm.sopimusNayta.sopimus
                                                        .laskutettavissa,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.avaaLaskutusaiheetPaivitaDialog,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        small: "",
                                                      },
                                                    },
                                                    [_vm._v(" update ")]
                                                  ),
                                                  _vm._v(
                                                    " Päivitä laskuttamattomat laskutusaiheet "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "item",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          item.laskutusjakso
                                            ? _c(
                                                "TableRow",
                                                { attrs: { "sub-row": true } },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.laskutusjakso
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c("td"),
                                                  _c("td"),
                                                  _c("td"),
                                                  _c("td"),
                                                  _c("td"),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(item.selite)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c("td"),
                                                  _c("td"),
                                                  _c("td"),
                                                  _c("td"),
                                                  _c("td"),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-tooltip", {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          small:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.muokkaaLaskutusjaksoa(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-pencil "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Muokkaa laskutusjaksoa"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                      _c("v-tooltip", {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          small:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.avaaLaskutaLaskutusjaksoDialog(
                                                                                item.id
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " anitta-icon-invoice "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Laskuta laskutusjakso"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                      _c("v-tooltip", {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          small:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.lisaaLaskutusaihe(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " add_circle "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Lisää laskutusjaksolle laskutusaihe"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                      _c("v-tooltip", {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          small:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.avaaDialogLaskutusjaksonLiite(
                                                                                item.id
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " attach_file "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Lisää liite laskutusjaksolle"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                      item.tiedosto_set.length
                                                        ? _c("v-tooltip", {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                text: "",
                                                                                icon: "",
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.avaaMenuContextLaskutusjaksoLiite(
                                                                                    $event,
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " anitta-icon-file-download "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: "default",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Lataa laskutusjakson liitteitä"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            item.subRows,
                                            function (laskutusaihe) {
                                              return _c(
                                                "TableRow",
                                                { key: laskutusaihe.id },
                                                [
                                                  !_vm.sopimusNayta.sopimus
                                                    .onko_lainasopimus
                                                    ? _c("td")
                                                    : _vm._e(),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-tooltip", {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            laskutusaihe.lasku
                                                              ? {
                                                                  key: "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      laskutusaihe.lasku &&
                                                                      laskutusaihe
                                                                        .lasku
                                                                        .ikoni_ja_vari
                                                                        ? _c(
                                                                            "StatusIcon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: laskutusaihe
                                                                                    .lasku
                                                                                    .ikoni_ja_vari
                                                                                    .ikoni,
                                                                                  size: "small",
                                                                                  "hover-icon":
                                                                                    "open-in-new",
                                                                                  "bg-color":
                                                                                    laskutusaihe
                                                                                      .lasku
                                                                                      .ikoni_ja_vari
                                                                                      .vari,
                                                                                  "event-listener":
                                                                                    on,
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.$router.push(
                                                                                      {
                                                                                        name: "laskutus-nayta",
                                                                                        params:
                                                                                          {
                                                                                            id: String(
                                                                                              laskutusaihe
                                                                                                .lasku
                                                                                                .id
                                                                                            ),
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                }
                                                              : null,
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Avaa laskun tiedot"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      laskutusaihe.lasku
                                                        ? _c(
                                                            "span",
                                                            {
                                                              on: {
                                                                contextmenu:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.avaaMenuContext(
                                                                      $event,
                                                                      {
                                                                        id: laskutusaihe
                                                                          .lasku
                                                                          .id,
                                                                      },
                                                                      "laskutus-nayta"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  attrs: {
                                                                    to: {
                                                                      name: "laskutus-nayta",
                                                                      params: {
                                                                        id: laskutusaihe
                                                                          .lasku
                                                                          .id,
                                                                      },
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        laskutusaihe
                                                                          .lasku
                                                                          .nro
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            laskutusaihe.laskutuspaiva_display
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            laskutusaihe.lasku
                                                              ? laskutusaihe
                                                                  .lasku.tila
                                                                  .selite
                                                              : "Laskuttamatta"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            laskutusaihe.erapaiva
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "max-width",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            laskutusaihe.selite
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            laskutusaihe.alvtunnus
                                                              ? laskutusaihe
                                                                  .alvtunnus
                                                                  .alvp
                                                              : laskutusaihe.alvp
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              laskutusaihe.ahinta
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$muotoileDesimaaliluku(
                                                              laskutusaihe.maara
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              laskutusaihe.veroton
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              laskutusaihe.verollinen
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-tooltip", {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          small:
                                                                            "",
                                                                          disabled:
                                                                            Boolean(
                                                                              laskutusaihe.lasku
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.muokkaaLaskutusaihetta(
                                                                                laskutusaihe
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              disabled:
                                                                                Boolean(
                                                                                  laskutusaihe.lasku
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-pencil "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Muokkaa laskutusaihetta"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                      _c("v-tooltip", {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          small:
                                                                            "",
                                                                          disabled:
                                                                            Boolean(
                                                                              laskutusaihe.lasku
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.avaaLaskutusaihePoistaDialog(
                                                                                laskutusaihe.id
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              disabled:
                                                                                Boolean(
                                                                                  laskutusaihe.lasku
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " delete "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Poista laskutusaihe"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.sopimusAsiakirjaItems.length
                          ? _c(
                              "div",
                              { staticClass: "py-4" },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-6" },
                                  [
                                    _c("CardTitle", {
                                      attrs: { text: "Asiakirjat" },
                                    }),
                                    _c("DataTableSopimusAsiakirja", {
                                      attrs: {
                                        items: _vm.sopimusAsiakirjaItems,
                                        sopimus: _vm.sopimusNayta.sopimus,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.kassaAlennuksetItems.length
                          ? _c(
                              "div",
                              { staticClass: "py-4" },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-6" },
                                  [
                                    _c("CardTitle", {
                                      attrs: { text: "Kassa-alennukset" },
                                    }),
                                    _c("v-data-table", {
                                      attrs: {
                                        items: _vm.kassaAlennuksetItems,
                                        headers:
                                          _vm.tableHeadersKassaAlennukset,
                                        "hide-default-footer": true,
                                        "items-per-page": 999,
                                        "no-data-text": "Ei kassa-alennuksia",
                                        dense: _vm.user.tiivisNakyma,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c("TableRow", [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$date(
                                                              item.voimassa
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.alennusp
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap max-width",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(item.alennus)
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        583486118
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Lisää asiakirjoja",
            "dialog-auki": _vm.asiakirjaDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.asiakirjaDialogAuki = false
            },
          },
        },
        [
          _c("FormLiitteet", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.asiakirjaFormLoading,
              "on-auki": _vm.asiakirjaDialogAuki,
            },
            on: {
              cancel: function ($event) {
                _vm.asiakirjaDialogAuki = false
              },
              submit: _vm.asiakirjaSubmit,
              close: function ($event) {
                _vm.asiakirjaDialogAuki = false
              },
            },
            model: {
              value: _vm.sopimusNayta.asiakirjaFormData,
              callback: function ($$v) {
                _vm.$set(_vm.sopimusNayta, "asiakirjaFormData", $$v)
              },
              expression: "sopimusNayta.asiakirjaFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Hyvitä sopimusta",
            "dialog-auki": _vm.hyvitaSopimustaDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.hyvitaSopimustaDialogAuki = false
            },
          },
        },
        [
          _c("FormSopimusHyvita", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.hyvitaSopimustaFormLoading,
              "on-auki": _vm.hyvitaSopimustaDialogAuki,
            },
            on: {
              cancel: function ($event) {
                _vm.hyvitaSopimustaDialogAuki = false
              },
              submit: _vm.hyvitaSopimustaSubmit,
              close: function ($event) {
                _vm.hyvitaSopimustaDialogAuki = false
              },
            },
            model: {
              value: _vm.sopimusNayta.hyvitaSopimustaFormData,
              callback: function ($$v) {
                _vm.$set(_vm.sopimusNayta, "hyvitaSopimustaFormData", $$v)
              },
              expression: "sopimusNayta.hyvitaSopimustaFormData",
            },
          }),
        ],
        1
      ),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa": _vm.menuContextNakyvissa,
          "position-x": _vm.menuContextX,
          "position-y": _vm.menuContextY,
          vaihtoehdot: _vm.menuContextVaihtoehdot,
        },
        on: {
          avaaValilehteen: _vm.avaaUudessaValilehdessa,
          "update:return-value": _vm.suljeMenuContext,
        },
      }),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Lisää laskutusjaksolle liite",
            "dialog-auki": _vm.laskutusjaksonLiiteDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.laskutusjaksonLiiteDialogAuki = false
            },
          },
        },
        [
          _c("FormLiite", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.laskutusjaksonLiiteFormLoading,
              "on-auki": _vm.laskutusjaksonLiiteDialogAuki,
            },
            on: {
              cancel: function ($event) {
                _vm.laskutusjaksonLiiteDialogAuki = false
              },
              submit: _vm.laskutusjaksonLiiteSubmit,
              close: function ($event) {
                _vm.laskutusjaksonLiiteDialogAuki = false
              },
            },
            model: {
              value: _vm.sopimusNayta.laskutusjaksonLiiteFormData,
              callback: function ($$v) {
                _vm.$set(_vm.sopimusNayta, "laskutusjaksonLiiteFormData", $$v)
              },
              expression: "sopimusNayta.laskutusjaksonLiiteFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title:
              _vm.sopimusNayta.laskusisaltoKirjoitusmoodi ===
              _vm.kirjoitusmoodit.LUOMINEN
                ? "Uusi laskurivi"
                : "Muokkaa laskuriviä",
            "dialog-auki": _vm.laskusisaltoDialogAuki,
            width: "88%",
          },
          on: {
            close: function ($event) {
              _vm.laskusisaltoDialogAuki = false
            },
          },
        },
        [
          _c("FormSopimusLaskusisalto", {
            ref: "laskusisaltoDialog",
            staticClass: "pa-4",
            attrs: {
              loading: _vm.laskusisaltoFormLoading,
              kirjoitusmoodi: _vm.sopimusNayta.laskusisaltoKirjoitusmoodi,
              "on-auki": _vm.laskusisaltoDialogAuki,
            },
            on: {
              cancel: function ($event) {
                _vm.laskusisaltoDialogAuki = false
              },
              submit: _vm.laskusisaltoSubmit,
              close: function ($event) {
                _vm.laskusisaltoDialogAuki = false
              },
            },
            model: {
              value: _vm.sopimusNayta.laskusisaltoFormData,
              callback: function ($$v) {
                _vm.$set(_vm.sopimusNayta, "laskusisaltoFormData", $$v)
              },
              expression: "sopimusNayta.laskusisaltoFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title:
              _vm.sopimusNayta.laskutusaiheKirjoitusmoodi ===
              _vm.kirjoitusmoodit.LUOMINEN
                ? "Luo laskutusaihe"
                : "Muokkaa laskutusaihetta",
            "dialog-auki": _vm.laskutusaiheDialogAuki,
            width: "88%",
          },
          on: {
            close: function ($event) {
              _vm.laskutusaiheDialogAuki = false
            },
          },
        },
        [
          _c("FormSopimusLaskutusaihe", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.laskutusaiheFormLoading,
              laskutusjaksot: _vm.laskutusjaksoItems,
              kirjoitusmoodi: _vm.sopimusNayta.laskutusaiheKirjoitusmoodi,
              "on-auki": _vm.laskutusaiheDialogAuki,
            },
            on: {
              cancel: function ($event) {
                _vm.laskutusaiheDialogAuki = false
              },
              submit: _vm.laskutusaiheSubmit,
              close: function ($event) {
                _vm.laskutusaiheDialogAuki = false
              },
            },
            model: {
              value: _vm.sopimusNayta.laskutusaiheFormData,
              callback: function ($$v) {
                _vm.$set(_vm.sopimusNayta, "laskutusaiheFormData", $$v)
              },
              expression: "sopimusNayta.laskutusaiheFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Muokkaa laskutusjaksoa",
            "dialog-auki": _vm.laskutusjaksoMuokkaaDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.laskutusjaksoMuokkaaDialogAuki = false
            },
          },
        },
        [
          _c("FormSopimusLaskutusjaksoMuokkaa", {
            staticClass: "pa-4",
            attrs: { loading: _vm.laskutusjaksoMuokkaaFormLoading },
            on: {
              cancel: function ($event) {
                _vm.laskutusjaksoMuokkaaDialogAuki = false
              },
              submit: _vm.laskutusjaksoMuokkaaSubmit,
              close: function ($event) {
                _vm.laskutusjaksoMuokkaaDialogAuki = false
              },
            },
            model: {
              value: _vm.sopimusNayta.laskutusjaksoMuokkaaFormData,
              callback: function ($$v) {
                _vm.$set(_vm.sopimusNayta, "laskutusjaksoMuokkaaFormData", $$v)
              },
              expression: "sopimusNayta.laskutusjaksoMuokkaaFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Lisää laskutusjaksoja",
            "dialog-auki": _vm.laskutusjaksoLisaaDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.laskutusjaksoLisaaDialogAuki = false
            },
          },
        },
        [
          _c("FormSopimusLaskutusjaksoLisaa", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.laskutusjaksoLisaaFormLoading,
              sopimus: _vm.sopimusNayta.sopimus,
              "on-auki": _vm.laskutusjaksoLisaaDialogAuki,
            },
            on: {
              cancel: function ($event) {
                _vm.laskutusjaksoLisaaDialogAuki = false
              },
              submit: _vm.laskutusjaksoLisaaSubmit,
              close: function ($event) {
                _vm.laskutusjaksoLisaaDialogAuki = false
              },
            },
            model: {
              value: _vm.sopimusNayta.laskutusjaksoLisaaFormData,
              callback: function ($$v) {
                _vm.$set(_vm.sopimusNayta, "laskutusjaksoLisaaFormData", $$v)
              },
              expression: "sopimusNayta.laskutusjaksoLisaaFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Siirrä toiselle päämiehelle",
            "dialog-auki": _vm.paamiesSiirraDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.paamiesSiirraDialogAuki = false
            },
          },
        },
        [
          _c("FormSopimusPaamiesSiirra", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.paamiesSiirraDialogLoading,
              paamiehet: _vm.sopimusNayta.sopimus.siirtopaamiehet,
              "on-auki": _vm.paamiesSiirraDialogAuki,
            },
            on: {
              cancel: function ($event) {
                _vm.paamiesSiirraDialogAuki = false
              },
              submit: _vm.paamiesSiirraSubmit,
              close: function ($event) {
                _vm.paamiesSiirraDialogAuki = false
              },
            },
            model: {
              value: _vm.sopimusNayta.paamiesSiirraFormData,
              callback: function ($$v) {
                _vm.$set(_vm.sopimusNayta, "paamiesSiirraFormData", $$v)
              },
              expression: "sopimusNayta.paamiesSiirraFormData",
            },
          }),
        ],
        1
      ),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa":
            _vm.sopimusNayta.menuContextLaskutusjaksonLiitteenAvausNakyvissa,
          "position-x":
            _vm.sopimusNayta.menuContextLaskutusjaksonLiitteenAvausX,
          "position-y":
            _vm.sopimusNayta.menuContextLaskutusjaksonLiitteenAvausY,
          vaihtoehdot:
            _vm.sopimusNayta.menuContextLaskutusjaksonLiitteenAvausVaihtoehdot,
        },
        on: {
          avaaLiite: _vm.avaaLaskutusjaksonLiite,
          "update:return-value": _vm.suljeMenuContextLaskutusjaksonLiite,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
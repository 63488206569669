<template>
  <div class="sopimus-tallenna grey lighten-2">
    <!-- Top bar -->
    <div class="sticky">
      <v-toolbar
        class="v-toolbar--sticky pl-6"
        color="primary"
        flat
        tabs
        :dark="true"
      >
        <v-btn
          icon
          @click="$router.go(-1)"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ routeTitle }}
        </v-toolbar-title>
      </v-toolbar>
    </div>


    <div class="px-6 py-12 main-div">
      <!-- Sopimuksen tallentamisen jälkeinen valikko -->
      <template v-if="luotuSopimus.id || muokattuSopimus.id">
        <v-container class="max-width--1200 px-6 py-4 mx-auto mb-12">
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  icon="repeat-invoice"
                  size="large"
                  :hover-icon="('open-in-new')"
                  bg-color="primary"
                  :event-listener="on"
                  @click="$router.push({
                    name: 'sopimus-nayta',
                    params: { id: (luotuSopimus.id ? luotuSopimus.id : muokattuSopimus.id) }
                  })"
                />
              </template>
              <template v-slot:default>
                <span>{{ $t('common:openRepeatingInvoice') }}</span>
              </template>
            </v-tooltip>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <span class="title">{{ sopimusTallennettuTeksti }} <router-link :to="{ name: 'sopimus-nayta', params: { id: (luotuSopimus.id ? luotuSopimus.id : muokattuSopimus.id) } }">{{ (luotuSopimus.id ? luotuSopimus.nro : muokattuSopimus.nro) }}</router-link>.
            </span>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-btn
              large
              :to="{ name: 'sopimus-selaa' }"
              class="mr-2"
            >
              {{ $t('common:contractSave.returnSearch') }}
            </v-btn>
            <v-btn
              large
              color="success"
              class="ml-2"
              @click="palautaAlkutilaan"
            >
              {{ $t('common:contractSave.createNewRepeatingInvoice') }}
            </v-btn>
          </v-row>
        </v-container>
      </template>


      <!-- Sopimuksen tiedot -->
      <template v-else>
        <div>
          <FormSopimus
            ref="formSopimus"
            v-model="sopimus"
            :muokkaamassa-sopimusta="muokkaamassaSopimusta"
            @avaa-dialog-asiakas="avaaDialogAsiakas"
            @avaa-dialog-asiakas-muistiinpanot="avaaDialogAsiakasMuistiinpanot"
            @avaa-dialog-paamies="avaaDialogPaamies"
            @muokkaus="naytaPoistumisvahvistus = true"
          />
        </div>

        <!-- Tallennus -->
        <v-row
          justify="center"
          class="max-width--1200 px-6 py-4 mx-auto mb-12"
        >
          <v-btn
            large
            color="success"
            class="mx-5"
            @click="tallennaSopimus"
          >
            {{ $route.params.muokattavaSopimus ? $t('common:saveChanges') : $t('common:contractSave.createRepeatingInvoice') }}
          </v-btn>
        </v-row>
      </template>
    </div>

    <!-- Dialog: Uusi päämies -->
    <CardDialog
      :title="sopimus.paamies.id ? $t('common:contractSave.editCreditor') : $t('common:newCreditor')"
      :dialog-auki="paamiesDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="1024"
      @close="(paamiesDialogAuki = false)"
    >
      <FormPaamies
        :kaare-objekti="sopimus"
        :on-auki="paamiesDialogAuki"
        @close="(paamiesDialogAuki = false)"
        @paamiesTallennettu="paivitaPaamies"
      />
    </CardDialog>

    <!-- Dialog: Uusi asiakas -->
    <CardDialog
      :title="sopimus.asiakas.id ? $t('common:editCustomer') : $t('common:newCustomer')"
      :dialog-auki="asiakasDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="1024"
      @close="(asiakasDialogAuki = false)"
    >
      <FormAsiakas
        :kaare-objekti="sopimus"
        :kenttien-valinnat="kenttienValinnat"
        :on-auki="asiakasDialogAuki"
        @close="(asiakasDialogAuki = false)"
        @asiakasTallennettu="paivitaAsiakas"
      />
    </CardDialog>

    <!-- Dialog: Asiakkaaseen liittyvät muistiinpanot -->
    <CardDialog
      title="Asiakkaaseen liittyvät muistiinpanot"
      :dialog-auki="asiakasMuistiinpanotDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4 pb-12"
      width="1024"
      @close="(asiakasMuistiinpanotDialogAuki = false)"
    >
      <AsiakasMuistiinpanotDatatable
        :items="muistiinpanoItems"
      />
    </CardDialog>

    <!-- <template :v-if="process.env.VUE_APP_DEV_MODE == 1"><pre>{{ sopimus }}</pre></template> -->
  </div>
</template>

<script>

import { Asiakas } from '@/class/Asiakas'
import Laskurivi from '@/class/Laskurivi'
import { Paamies } from '@/class/Paamies'
import Sopimus from '@/class/Sopimus'
import {
  AsiakasMuistiinpanotDatatable,
  FormAsiakas,
  FormPaamies,
  FormSopimus,
} from '@/components'
import PoistumisvahvistusMixin from '@/mixins/PoistumisvahvistusMixin'

export default {
  name: 'SopimusTallenna',
  components: {
    AsiakasMuistiinpanotDatatable,
    FormAsiakas,
    FormPaamies,
    FormSopimus,
  },
  mixins: [PoistumisvahvistusMixin],
  data () {
    return {
      asiakasDialogAuki: false,
      asiakasMuistiinpanotDialogAuki: false,
      kenttienValinnat: {},
      paamiesDialogAuki: false,
      sopimus: new Sopimus(true),
      luotuSopimus: {},
      muokattuSopimus: {},
    }
  },
  computed: {
    muokkaamassaSopimusta () {
      return !!this.$route.params.muokattavaSopimus
    },
    sopimusTallennettuTeksti () {
      return this.$t('common:contractSave.repeatingInvoiceSavedWithContractoNo')
    },
    warningStyle () {
      return `color: ${this.$vuetify.theme.warning}`
    },
    routeTitle () {
      const sopimus = this.$route.params.muokattavaSopimus
      return sopimus ? this.$t('common:contractSave.editRepeatingInvoice') +` ${sopimus.nro}` : this.$t('common:contractSave.createNewRepeatingInvoice')
    },
    muistiinpanoItems () {
      if (!this.sopimus.asiakas.muistiinpano_set) return []

      const muistiinpanot = this.sopimus.asiakas.muistiinpano_set.filter(item => {
        return !item.paamiesviesti
      })

      return muistiinpanot.map(item => {
        return {
          lahettaja: item.lahettaja.username,
          aika: item.aika,
          lasku: item.lasku,
          sisalto: item.data,
        }
      })
    },
  },
  watch: {
    'sopimus.asiakas.id': {
      async handler (id) {
        this.haeAsiakkaanKenttienValinnat(id)
      }
    },
  },
  created () {
    if (this.muokkaamassaSopimusta) {
      this.tuoPalvelimeltaHaettuMuokattavaSopimus(this.$route.params.muokattavaSopimus)
    } else {
      if (!this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_sopimus_uusi')) {
        this.$router.replace({ name: 'error403' })
        return
      }
      this.haeAsiakkaanKenttienValinnat()
    }
  },
  methods: {
    tallennaSopimus () {
      if (!this.validoiSopimus()) return

      this.sopimusSubmit()
    },
    sopimusSubmit () {
      if (this.muokkaamassaSopimusta) {
        this.tallennaMuutokset()
      } else {
        this.tallennaUusiSopimus()
      }
    },
    async tallennaMuutokset () {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Sopimus,
          {
            method: 'PUT',
            url: '',
            body: this.sopimus.getPostData(true),
            params: {
              id: this.sopimus.id
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.muokattuSopimus = request.result.body
        this.naytaPoistumisvahvistus = false

        this.$naytaOnnistumisilmoitus(this.$t('common:contractSave.repeatingInvoiceSaved'))

        this.$router.push({
          name: 'sopimus-nayta',
          params: { id: this.muokattuSopimus.id }
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:contractSave.repeatingInvoiceSaveFailed'))
      }
    },
    async tallennaUusiSopimus () {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Sopimukset,
          {
            method: 'POST',
            url: '',
            body: this.sopimus.getPostData(),
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.luotuSopimus = request.result.body
        this.naytaPoistumisvahvistus = false

        this.$naytaOnnistumisilmoitus(this.$t('common:contractSave.repeatingInvoiceSaved'))
      } catch(e) {
        this.$oletusVirheenkasittely(e, this.$t('common:contractSave.repeatingInvoiceSaveFailed'))
      }
    },
    validoiSopimus () {
      let lomakeValidi = true

      if (!this.$refs.formSopimus.validate()) {
        this.$naytaVirheilmoitus(this.$t('common:checkFields'))
        lomakeValidi = false
      }

      return lomakeValidi
    },
    avaaDialogAsiakas () {
      this.asiakasDialogAuki = true
    },
    avaaDialogAsiakasMuistiinpanot () {
      this.asiakasMuistiinpanotDialogAuki = true
    },
    avaaDialogPaamies () {
      this.paamiesDialogAuki = true
    },
    async tuoPalvelimeltaHaettuMuokattavaSopimus (sopimus) {
      this.sopimus.id = sopimus.id
      this.sopimus.paamies = await Paamies.haePaamiesTiedotLuontitoiminnoille(sopimus.paamies.id)

      // Asetetaan asiakas vasta sen jälkeen kun päämiehen asettaminen on laukaissut ja tyhjentänyt asiakashaun
      setTimeout(() => {
        this.sopimus.asiakas = new Asiakas(sopimus.asiakas)
      }, 0)

      this.sopimus.alkamispaiva = sopimus.alkamispaiva
      this.sopimus.loppumispaiva = sopimus.loppumispaiva
      this.sopimus.erapaiva = sopimus.erapaiva
      this.sopimus.kassaalennus_maksuaika = sopimus.kassaalennus_maksuaika
      this.sopimus.kassaalennus_prosentti = sopimus.kassaalennus_prosentti
      this.sopimus.kirjepohja_id = sopimus.kirjepohja_id
      this.sopimus.lahetyspaivanPeruste = this.sopimus.erapaiva ? 'erapaiva' : 'laskutuspaiva'
      this.sopimus.laji = sopimus.laji
      this.sopimus.laskunlahetys = sopimus.laskunlahetys
      this.sopimus.laskunteksti = sopimus.laskunteksti
      this.sopimus.laskutettu = sopimus.laskutettu
      this.sopimus.laskutusjakso = sopimus.laskutusjakso
      this.sopimus.laskutuspaiva = sopimus.laskutuspaiva
      this.sopimus.maksuehto = sopimus.maksuehto
      this.sopimus.paivanumero = sopimus.laskutuspaiva ? sopimus.laskutuspaiva : sopimus.erapaiva
      this.sopimus.pm_viite = sopimus.pm_viite
      this.sopimus.rivit = []
      this.sopimus.seuraava_laskutuspaiva = sopimus.erapaiva ? sopimus.seuraava_erapaiva : sopimus.seuraava_laskutuspaiva
      for (const rivi of sopimus.sopimuslaskusisalto_set) {
        this.sopimus.rivit.push(new Laskurivi(rivi))
      }
      this.sopimus.sopimusaika = sopimus.sopimusaika
      this.sopimus.sopimusjakso = sopimus.sopimusjakso
      this.sopimus.sopimuslisatieto_set = sopimus.sopimuslisatieto_set
      this.sopimus.viitteemme = sopimus.viitteemme
      this.sopimus.viitteenne = sopimus.viitteenne
      this.sopimus.viivastyskorko = sopimus.viivastyskorko
      this.sopimus.asetaLisatietoKuukauttaAikaisemminJasenmuuttujaan()
    },
    async haeAsiakkaanKenttienValinnat (asiakas_id = '') {
      this.kenttienValinnat = await Asiakas.haeAsiakkaanKenttienValinnat(asiakas_id)
    },
    palautaAlkutilaan () {
      this.luotuSopimus = {}
      this.muokattuSopimus = {}
      this.sopimus = new Sopimus(true)
    },
    paivitaAsiakas (asiakas) {
      this.sopimus.asiakas = asiakas
    },
    paivitaPaamies (paamies) {
      this.sopimus.paamies = paamies
    },
  },
}

</script>

<style lang="scss" scoped>

.main-div {
  min-height: calc(100vh - 64px);
}

</style>
